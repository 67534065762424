import {colors, StyledFC} from '@bettermarks/bm-ui-components';
import React from 'react';

export enum Orientation {
  left = 'left',
  right = 'right',
  up = 'up',
  down = 'down'
}

interface CaretProps {
  orientation: Orientation;
  fill?: string;
  width?: number;
  height?: number;
}

export const CaretIcon: StyledFC<CaretProps> = ({
  orientation,
  fill,
  width,
  height
}) => {
  switch (orientation) {
    case Orientation.left:
      return <CaretLeftIcon fill={fill} width={width} height={height} />;
    case Orientation.right:
      return <CaretRightIcon fill={fill} width={width} height={height} />;
    case Orientation.up:
      return <CaretUpIcon fill={fill} width={width} height={height} />;
    case Orientation.down:
      return <CaretDownIcon fill={fill} width={width} height={height} />;
    default:
      return <CaretRightIcon fill={fill} width={width} height={height} />;
  }
};

const CaretLeftIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 6}
    height={height || 11}
    viewBox='0 0 6 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.40006 5.29964L6 9.89958L5.29997 10.5996L5.83022e-07 5.29964L5.29997 -0.00033186L6 0.699697L1.40006 5.29964Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);

const CaretRightIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 6}
    height={height || 11}
    viewBox='0 0 6 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.59994 5.29997L0 0.700029L0.700029 0L6 5.29997L0.700029 10.5999L0 9.89991L4.59994 5.29997Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);

const CaretUpIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 12}
    height={height || 7}
    viewBox='0 0 12 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00017 1.69889L1.40022 6.29883L0.700195 5.5988L6.00017 0.298828L11.3001 5.5988L10.6001 6.29883L6.00017 1.69889Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);

const CaretDownIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 11}
    height={height || 6}
    viewBox='0 0 11 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.30013 4.59994L9.90007 -3.05993e-08L10.6001 0.700029L5.30013 6L0.000156452 0.700029L0.700186 -4.32739e-07L5.30013 4.59994Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);
