import {StyledFC, dimensions, colors} from '@bettermarks/bm-ui-components';
import styled from 'styled-components';

interface LegendProps {
  icon?: any;
  label: string;
}

const _Legend: StyledFC<LegendProps> = ({className, icon, label}) => {
  return (
    <div className={className}>
      {icon && icon}
      <span>{label}</span>
    </div>
  );
};

export const Legend = styled(_Legend)`
  display: inline-flex;
  align-items: center;
  color: ${colors.cGray700};
  svg + span,
  img + span {
    margin-left: ${dimensions.spaceS};
  }
`;
