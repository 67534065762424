import React from 'react';
import {Grid} from '../../components/LayoutComponents';
import {
  Group,
  Headline,
  LibraryLinkedTile
} from './librarySelectionSubComponents';
import {LibraryGroup} from '../../types';
import {useRoutes} from '../../services/route.service';

export const LibrarySelection: React.FC<{
  libraryGroups: LibraryGroup[];
  lastLibrary?: string;
}> = ({libraryGroups, lastLibrary}) => {
  const RouteService = useRoutes();
  return (
    <>
      {libraryGroups.map((libGroup, i) => (
        <Group key={i}>
          <Headline>{libGroup.libraryGroup}</Headline>
          <Grid>
            {libGroup.content.map(libGroupItem =>
              LibraryLinkedTile(
                (lastLibrary &&
                  libGroupItem.id === lastLibrary && {
                    ...libGroupItem,
                    ...{isActive: true}
                  }) ||
                  libGroupItem,
                RouteService.libraryRoute(libGroupItem.id)
              )
            )}
          </Grid>
        </Group>
      ))}
    </>
  );
};
