import styled from 'styled-components/macro';
import {Book, Domain, Library, SuperTopic} from '../../types';
import {Overlay, useOverlay} from '../../components/Overlay';
import {memo, useCallback, useEffect, useState} from 'react';
import {
  ButtonLayout,
  colors,
  Decoration,
  ModuleDetailOpenHandler,
  StyledFC,
  ThematicBanner,
  TipPosition
} from '@bettermarks/bm-ui-components';

import {Counter} from '../../components/Counter';
import {Grid, Group} from '../../components/LayoutComponents';
import {useNavigate, useParams} from 'react-router-dom';
import {useRoutes} from '../../services/route.service';
import {useLibrary} from '../../services/library.service';
import {libraryUtils} from '../../services/library.utils';
import {usePlanning} from '../../services/planning.service';
import {ActionButtonKnob, bookImage, dialogue} from './TileGridSubComponents';
import {Headline} from './librarySelectionSubComponents';
import {ModuleProps, ModuleTile} from '../../components/ModuleTile';
import {CalendarIcon, DocumentPreviewIcon} from '../../components/Icons';
import {LibraryIndicator} from './LibraryIndicator';

const MemoizedModuleTile = memo(ModuleTile);
const LoadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ToolTipWrapper = styled.div`
  display: flex;
`;

export const TileGrid: StyledFC<{
  library: Library;
  showDomainBanner: boolean;
  selectedLibrary: string;
}> = ({library, showDomainBanner, selectedLibrary}) => {
  const {libraryId} = useParams<{libraryId: string}>();
  const LibraryService = useLibrary();
  const PlanningService = usePlanning();
  const OverlayService = useOverlay();
  const navigate = useNavigate();
  const RouteService = useRoutes();

  const [actionTipState, setActionTipState] = useState<{
    [id: string]: (isOpen: boolean) => void;
  }>({});
  const [plannedModules, updatePlannedModules] = useState<{
    [id: string]: boolean;
  }>({});

  let modalOpen: string | false;
  let closeModalFn: () => void | undefined;

  const handleModalToggleClick: ModuleDetailOpenHandler = (
    id,
    setDetailPropsAndOpen
  ) => {
    modalOpen = (id !== modalOpen && id) || false;
    if (modalOpen) {
      Object.keys(actionTipState).forEach(id => actionTipState[id](false));
      closeModalFn = setDetailPropsAndOpen(LibraryService.chapterIntro(id));
      OverlayService.fadeIn(() => {
        closeModalFn && closeModalFn();
        OverlayService.deactivate();
        modalOpen = false;
      });
    } else {
      OverlayService.deactivate();
      closeModalFn && closeModalFn();
    }
  };

  const handleAddToPlanning: ModuleProps['onToggleActionTip'] = (
    id,
    isOpen,
    setActionTipVisibility
  ) => {
    if (modalOpen) {
      modalOpen = false;
      OverlayService.deactivate();
      closeModalFn && closeModalFn();
    }

    Object.keys(actionTipState).forEach(id => actionTipState[id](false));

    if (isOpen) {
      setActionTipState({
        ...actionTipState,
        ...{[id]: setActionTipVisibility}
      });
    } else {
      const updated = {...actionTipState};
      delete updated[id];
      setActionTipState(updated);
    }

    setActionTipVisibility(isOpen);
  };

  const addToPlan = (id: string, libId: string, color: string) => {
    const added = PlanningService.add(id, libId, color);
    actionTipState[id] && actionTipState[id](false);
    if (modalOpen) {
      modalOpen = false;
      OverlayService.deactivate();
      closeModalFn && closeModalFn();
    }

    setTimeout(
      () => updatePlannedModules({...plannedModules, ...{[id]: added}}),
      300
    );
  };

  const handleModuleOpen = useCallback((id: string) => {
    OverlayService.triggerClick();
    navigate(RouteService.moduleRoute(libraryId!, id));
  }, []);

  useEffect(() => {
    updatePlannedModules(
      PlanningService.planningList().reduce(
        (acc, cur) => ({...acc, ...{[cur.id]: true}}),
        {}
      )
    );
  }, []);

  const counter = library
    .flatMap(d => d.superTopics.filter(st => st.books.length))
    .flatMap(st => st.books).length;

  const stopProp = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };
  const goToPlanning = (e: Event) => {
    navigate('/planning');
    return stopProp(e);
  };

  const actionButton = ActionButtonKnob(goToPlanning, addToPlan, stopProp);

  return library.length ? (
    <>
      <Overlay />
      <LibraryIndicator
        image='/library-icons/bf-school-class-five.svg'
        label={selectedLibrary}
      />
      <Counter amount={counter} label={'Themen'} />
      {library
        .filter((domain: Domain) =>
          domain.superTopics.some(st => st.books.length)
        )
        .map((domain: Domain) => (
          <Group key={`${libraryUtils.sanitizeLabel(domain.name)}`}>
            <Headline>{domain.name}</Headline>
            <Grid maxCols={4} maxWidth={320}>
              {(showDomainBanner && (
                <ThematicBanner
                  key={`${libraryUtils.sanitizeLabel(domain.name)}--banner`}
                  title={domain.name}
                  color={domain.color}
                  image={`/${domain.banner}`}
                  width={600}
                  height={162}
                />
              )) || <></>}

              {domain.superTopics
                .filter(s => s.books.length)
                .map((superTopic: SuperTopic) =>
                  superTopic.books.map((book: Book) => {
                    return (
                      <MemoizedModuleTile
                        id={book.id}
                        key={book.id}
                        modalTitle='Vorschau'
                        title={book.name}
                        color={domain.color}
                        isPlanned={plannedModules[book.id]}
                        onOpenModuleDetails={handleModalToggleClick}
                        onOpenModule={handleModuleOpen}
                        onToggleActionTip={handleAddToPlanning}
                        onOpenPlanning={goToPlanning}
                        onAddToPlanning={() =>
                          addToPlan(book.id, libraryId!, domain.color)
                        }
                        customPreviewIcon={<DocumentPreviewIcon />}
                        url={RouteService.moduleRoute(libraryId!, book.id)}
                        customAddToPlaningIcon={
                          <CalendarIcon
                            fill={colors.cBlue700}
                            decoration={
                              (plannedModules[book.id] &&
                                Decoration.checkmark) ||
                              Decoration.plus
                            }
                          />
                        }
                        image={bookImage(book.bookIcon)}
                        dialogue={dialogue(plannedModules[book.id])}
                        closeLabel='Abbrechen'
                        actionButton={actionButton(
                          book.id,
                          plannedModules[book.id],
                          libraryId!,
                          domain.color
                        )}
                        buttonLayout={ButtonLayout.column}
                        tipPosition={TipPosition.center}
                        activeTip={true}
                        onCloseTip={function (e: Event): boolean | void {
                          throw new Error('Function not implemented.');
                        }}
                      />
                    );
                  })
                )}
            </Grid>
          </Group>
        ))}
    </>
  ) : (
    <LoadingMessage>Bibliothek wird geladen</LoadingMessage>
  );
};
