import {
  StyledFC,
  CaretDownIcon,
  dimensions,
  colors,
  SearchIcon
} from '@bettermarks/bm-ui-components';
import styled from 'styled-components';

interface FakeProps {
  label: string;
  onClick?: () => void;
}

const _FakeDropdown: StyledFC<FakeProps> = ({className, label, onClick}) => {
  return (
    <div className={className} onClick={onClick}>
      {label}
      <CaretDownIcon />
    </div>
  );
};

export const FakeDropdown = styled(_FakeDropdown)`
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 160px;
  justify-content: space-between;
  color: ${colors.cBlue700};
  padding: ${dimensions.spaceXs} ${dimensions.spaceS};
  border: 1px solid ${colors.cBlue700};
  border-radius: 2px;
  cursor: pointer;
  svg {
    margin-left: ${dimensions.spaceXl};
  }
  polygon {
    fill: ${colors.cBlue700};
  }
`;

const _FakeSearch: StyledFC<FakeProps> = ({className, label, onClick}) => {
  return (
    <div className={className}>
      <input
        onMouseDown={onClick}
        onKeyUp={onClick}
        type='text'
        placeholder={label}
      />
      <SearchIcon />
    </div>
  );
};

export const FakeSearch = styled(_FakeSearch)`
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid ${colors.cGray600};
  padding: 0 ${dimensions.spaceS};
  border-radius: 20px;
  path {
    fill: ${colors.cGray600};
  }
  input {
    outline: none;
    font-family: 'Roboto';
    border: none;
    font-size: ${dimensions.fontSizeM};

    color: ${colors.cGray700};
    &::placeholder {
      color: ${colors.cGray500};
    }
  }
`;
