import {StyledFC, dimensions, colors} from '@bettermarks/bm-ui-components';
import styled, {css} from 'styled-components';
import {Knob, KnobSize, KnobType, KnobVariant} from '../Knob';

import {
  ActionTipProps,
  ButtonLayout,
  TipOrientation,
  TipPosition
} from './types';

const Dialogue = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.spaceM};
  p {
    margin: 0;
    line-height: ${dimensions.lineHeightM2};
  }
`;

export const Footer = styled.footer<Pick<ActionTipProps, 'buttonLayout'>>`
  display: flex;
  flex-wrap: wrap;
  gap: ${dimensions.spaceS};
  ${Knob} {
    min-height: 40px;
    padding-top: ${dimensions.spaceXs};
    padding-bottom: ${dimensions.spaceXs};
  }
  ${p => {
    switch (p.buttonLayout) {
      case ButtonLayout.row:
        return css`
          justify-content: flex-end;
        `;
      case ButtonLayout.column:
        return css`
          flex-direction: column;
          align-items: flex-start;
          ${Knob} {
            justify-content: center;

            width: 100%;
          }

          ${Knob}:nth-child(1) {
            order: 2;
          }
          ${Knob}:nth-child(2) {
            order: 1;
          }
        `;
      default:
        return css`
          justify-content: flex-end;
        `;
    }
  }};
`;

const _ActionTip: StyledFC<ActionTipProps> = ({
  className,
  dialogue,
  closeLabel,
  onCloseTip,
  actionButton,
  buttonLayout
}) => {
  return (
    <div className={className}>
      <Dialogue>{dialogue}</Dialogue>
      <Footer buttonLayout={buttonLayout}>
        {closeLabel && onCloseTip && (
          <Knob
            type={KnobType.ghost}
            size={KnobSize.medium}
            variant={KnobVariant.secondary}
            label={closeLabel}
            onClick={onCloseTip}
            stretch={buttonLayout === ButtonLayout.column ? true : false}
          />
        )}
        {actionButton}
      </Footer>
    </div>
  );
};

const northTipBefore = css`
  border-color: transparent transparent ${colors.cWhite} transparent;
  border-width: 0 12px 12px 12px;
  top: -12px;
`;

const northTipAfter = css`
  border-color: transparent transparent ${colors.cGray400} transparent;
  border-width: 0 12px 12px 12px;
  top: -13px;
`;

const southTipBefore = css`
  border-width: 12px 12px 0 12px;
  border-color: ${colors.cWhite} transparent transparent transparent;
  bottom: -12px;
`;

const southTipAfter = css`
  border-width: 12px 12px 0 12px;
  border-color: ${colors.cGray400} transparent transparent transparent;
  bottom: -13px;
`;

export const ActionTip = styled(_ActionTip)`
  border-radius: ${dimensions.spaceXxs};
  &::before,
  &::after {
    position: absolute;

    ${p => {
      if (typeof p.tipPosition === 'number') {
        return css`
          left: ${p.tipPosition}px;
        `;
      }
      switch (p.tipPosition) {
        case TipPosition.left:
          return css`
            left: -0.5px;
          `;
        case TipPosition.center:
          return css`
            left: calc(50% - 12px);
          `;
        case TipPosition.right:
          return css`
            right: -0.5px;
          `;
      }
    }};
  }
  &::before {
    border-style: solid;
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    z-index: 1;
    ${p => {
      switch (p.tipOrientation) {
        case TipOrientation.north:
          return northTipBefore;
        case TipOrientation.south:
          return southTipBefore;
        default:
          return northTipBefore;
      }
    }};
  }
  &::after {
    border-style: solid;
    content: '';
    display: inline-block;
    height: 0;
    width: 0;

    ${p => {
      switch (p.tipOrientation) {
        case TipOrientation.north:
          return northTipAfter;
        case TipOrientation.south:
          return southTipAfter;
        default:
          return northTipAfter;
      }
    }};
  }
  ${p =>
    p.tipOrientation === 'south' && p.tipPosition === 'left'
      ? css`
          border-bottom-left-radius: 0;
        `
      : p.tipOrientation === 'south' && p.tipPosition === 'right'
      ? css`
          border-bottom-right-radius: 0;
        `
      : p.tipOrientation === 'north' && p.tipPosition === 'left'
      ? css`
          border-top-left-radius: 0;
        `
      : p.tipOrientation === 'north' &&
        p.tipPosition === 'right' &&
        css`
          border-top-right-radius: 0;
        `};

  background: ${colors.cWhite};
  padding: ${dimensions.spaceM};
  border: 1px solid ${colors.cGray400};
  width: calc(100% + 2px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  position: absolute;
  left: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  gap: ${dimensions.spaceM};
  ${p =>
    p.activeTip
      ? css`
          transform: translate3d(0, 18px, 0);
          opacity: 1;
          z-index: 1;
          visibility: visible;
        `
      : css`
          transform: translate3d(0, 90px, 0);
          opacity: 0;
          z-index: 1;
          visibility: hidden;
        `};
  & p {
    color: ${colors.cGray800};
  }
`;

export default ActionTip;
