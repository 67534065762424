import styled from 'styled-components/macro';
import {dimensions, StyledFC} from '@bettermarks/bm-ui-components';
import {Headline} from './librarySelectionSubComponents';

interface LibraryIndicatorProps {
  image: string;
  label: string;
}
const _LibraryIndicator: StyledFC<LibraryIndicatorProps> = ({
  className,
  image,
  label
}) => {
  return (
    <div className={className}>
      <img width='48' height='48' src={image} alt={label} />
      <Headline>{label}</Headline>
    </div>
  );
};

export const LibraryIndicator = styled(_LibraryIndicator)`
  display: flex;
  align-items: center;
  margin: ${dimensions.spaceXl} 0 ${dimensions.spaceXs};
  padding: 0 ${dimensions.spaceM};
  ${Headline} {
    margin: 0 0 0 ${dimensions.spaceS};
  }
`;
