import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const FileListIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 29}
    height={height || 28}
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2389 0V19.7927L5.54695 12.1036L4.55851 13.0934L13.9376 22.4725L23.318 13.0934L22.3282 12.1036L14.639 19.7927V0H13.2389ZM26.6013 20.9976V26.5992H1.40007V20.9976H0V27.9993H28.0013V20.9976H26.6013Z'
      fill={fill || colors.cGray700}
    />
  </svg>
);
