import {Context, createContext, useContext} from 'react';
import {BehaviorSubject, Observable} from 'rxjs';

export enum PtVersion {
  LibraryGroupsPerClass = 'LibraryGroupsPerClass',
  LibraryGroupsPerSubject = 'LibraryGroupsPerSubject'
}
export interface PtStorageService {
  setVersion: (version: PtVersion) => void;
  getVersion: () => PtVersion;
  saveSelectedLibrary: (id: string) => void;
  selectedlibrary$: () => Observable<string>;
  load: <T>(key: string, session?: boolean) => T | null;
  save: (key: string, value: any, session?: boolean) => void;
}

const PREF = 'pt__';

const defaultVersion: keyof typeof PtVersion = PtVersion.LibraryGroupsPerClass;

let selectedLibrarySubj: BehaviorSubject<string>;

export const StorageService: PtStorageService = {
  setVersion(ptVersion: PtVersion) {
    this.save('ptVersion', ptVersion);
  },
  getVersion() {
    return PtVersion[
      (this.load('ptVersion') || defaultVersion) as keyof typeof PtVersion
    ];
  },
  saveSelectedLibrary(id: string) {
    this.selectedlibrary$() && selectedLibrarySubj.next(id);
    this.save(this.getVersion() + '__library', id);
  },
  load<T>(key: string, session: boolean = true): T | null {
    const value = JSON.parse(
      ((session && sessionStorage) || localStorage).getItem(PREF + key) ||
        'null'
    );

    return value === null ? null : (value as T);
  },
  save(key: string, value: any, session: boolean = true): void {
    ((session && sessionStorage) || localStorage).setItem(
      PREF + key,
      JSON.stringify(value)
    );
  },

  selectedlibrary$() {
    if (!selectedLibrarySubj) {
      selectedLibrarySubj = new BehaviorSubject<string>(
        StorageService.load(this.getVersion().toString())!
      );
    }

    return selectedLibrarySubj.asObservable();
  }
};

const PtStorageContext: Context<PtStorageService> =
  createContext(StorageService);

export const useStorage = () => useContext<PtStorageService>(PtStorageContext);
export const PtStorageProvider: React.FC<Partial<PtStorageService>> = ({
  children,
  ...apiMethods
}) => {
  const api: PtStorageService = {
    setVersion: apiMethods.setVersion || StorageService.setVersion,
    getVersion: apiMethods.getVersion || StorageService.getVersion,
    saveSelectedLibrary:
      apiMethods.saveSelectedLibrary || StorageService.saveSelectedLibrary,
    selectedlibrary$:
      apiMethods.selectedlibrary$ || StorageService.selectedlibrary$,
    load: apiMethods.load || StorageService.load,
    save: apiMethods.save || StorageService.save
  };
  return (
    <PtStorageContext.Provider value={api}>
      {children}
    </PtStorageContext.Provider>
  );
};
