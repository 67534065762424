import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import LibrarySelector from './LibrarySelector';
import 'bf-school.svg';
import {colors, ToolBar, ToolBarType} from '@bettermarks/bm-ui-components';
import {useRoutes} from '../services/route.service';
import {handleAlertMessage} from '../helpers/Alert';
import {FakeSearch} from './Fake';
import {useStorage} from '../services/pt-storage.service';
import {WhiteboardIcon} from './Icons';
import {Knob, KnobSize, KnobType, KnobVariant} from './Knob';

const MyToolBar = styled(ToolBar)`
  background: ${colors.cWhite};
  border-bottom: 1px solid rgb(153, 153, 153);
`;

export const SearchBar: React.FC<{
  isSelectionPage?: boolean;
  libraryName?: string;
}> = ({isSelectionPage, libraryName}) => {
  const RouteService = useRoutes();
  const StorageService = useStorage();
  const [librarySelectionLink, setLibSelection] = useState<string>('');

  useEffect(() => {
    setLibSelection(RouteService.librarySelection(StorageService.getVersion()));
  }, [libraryName, isSelectionPage]);
  return (
    <MyToolBar
      toolBarType={ToolBarType.Secondary}
      leftArea={
        !isSelectionPage && libraryName ? (
          <LibrarySelector
            label='Bereichauswahl'
            routerLink={librarySelectionLink}
          />
        ) : (
          ' '
        )
      }
      middleArea={<FakeSearch label='Suche' onClick={handleAlertMessage} />}
      rightArea={
        !isSelectionPage &&
        (libraryName?.includes('5') || libraryName?.includes('6')) ? (
          <Knob
            label='Tafelbilder'
            iconPrepended={<WhiteboardIcon />}
            type={KnobType.ghost}
            size={KnobSize.medium}
            variant={KnobVariant.primary}
            onClick={() =>
              window.open('https://einstiege.bettermarks.com/', '_blank')
            }
          />
        ) : (
          ' '
        )
      }
    />
  );
};
