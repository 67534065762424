import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const FemListIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 27}
    height={height || 32}
    viewBox='0 0 27 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.33058 13.037H21.4215V11.8519H8.33058V13.037Z'
      fill={fill || colors.cGray700}
    />
    <path
      d='M8.33058 17.7778H21.4215V16.5926H8.33058V17.7778Z'
      fill={fill || colors.cGray700}
    />
    <path
      d='M8.33058 22.5185H21.4215V21.3333H8.33058V22.5185Z'
      fill={fill || colors.cGray700}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.57025 0V28.4444H26.1818V7.11111L19.0413 0H3.57025ZM4.76033 1.18519H17.8506V8.2963H24.9917V27.2593H4.76033V1.18519ZM19.0413 1.67763L24.4973 7.11111H19.0413V1.67763Z'
      fill={fill || colors.cGray700}
    />
    <path
      d='M0 2.96296V32H23.206V29.6296H22.0165V30.8148H1.18949V4.14815H2.37957V2.96296H0Z'
      fill={fill || colors.cGray700}
    />
  </svg>
);
