import {useState, useEffect} from 'react';

function getModalPosition(dimension: number) {
  const {innerWidth: width} = window;
  const position = (width - dimension) / 2;
  return {
    position
  };
}

export default function useWindowDimensions(dimension: number) {
  const [windowDimensions, setWindowDimensions] = useState(
    getModalPosition(dimension)
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getModalPosition(dimension));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
