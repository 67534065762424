import {CalendarIcon, colors, Decoration} from '@bettermarks/bm-ui-components';
import {ReactElement} from 'react';
import {CaretIcon, Orientation} from '../../components/Icons';
import {Knob, KnobType, KnobVariant} from '../../components/Knob';

export const ActionButtonKnob =
  (
    goToPlanning: (e: Event) => boolean,
    addToPlan: (id: string, libraryId: string, color: string) => void,
    stopProp: (e: Event) => boolean
  ) =>
  (
    id: string,
    isPlanned: boolean,
    libraryId: string,
    color: string
  ): ReactElement =>
    (isPlanned && (
      <Knob
        type={KnobType.button}
        variant={KnobVariant.primary}
        iconPrepended={
          <CalendarIcon
            decoration={(isPlanned && Decoration.checkmark) || Decoration.plus}
          />
        }
        iconAppended={<CaretIcon orientation={Orientation.right} />}
        label='In der Planung ansehen'
        colorizeIcon={true}
        onClick={goToPlanning}
        maxHeight={40}
      />
    )) || (
      <Knob
        type={KnobType.button}
        iconPrepended={
          <CalendarIcon
            fill={colors.cWhite}
            decoration={(isPlanned && Decoration.checkmark) || Decoration.plus}
          />
        }
        label='Zur Planung hinzufügen'
        onClick={(e: Event) => {
          addToPlan(id, libraryId, color);
          return stopProp(e);
        }}
        maxHeight={40}
      />
    );

export const bookImage = (bookIcon?: string): string =>
  (bookIcon && !bookIcon.endsWith('.png') && `/topic-icons/${bookIcon}`) ||
  '/topic-icons/capital-X.svg';
export const dialogue = (isPlanned: boolean) =>
  (isPlanned && 'Dieses Modul wurde bereits zur Planung hinzugefügt.') ||
  'Möchten Sie dieses Modul zur Planung hinzufügen?';
