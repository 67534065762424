export enum ButtonLayout {
  row = 'row',
  column = 'column'
}

export enum TipPosition {
  left = 'left',
  center = 'center',
  right = 'right'
}

export enum TipOrientation {
  north = 'north',
  south = 'south'
}

export interface ActionTipProps {
  dialogue: any;
  activeTip: boolean;
  closeLabel?: string;
  onCloseTip?: (e: Event) => void | boolean;
  actionButton: any;
  alternateButtonLayout?: boolean;
  buttonLayout?: ButtonLayout;
  tipOrientation?: TipOrientation | string;
  tipPosition?: TipPosition | number;
}
