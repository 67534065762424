import {Context, createContext, useContext} from 'react';
import {
  PlanningItem,
  PlanningListItem,
  PlanningListState,
  PlanningService
} from '../pages/Planning/types';
import libraryMappings from '../data/libraries/color-and-icon-mappings.json';
import {StorageService} from './pt-storage.service';
import {arrayMove} from '@dnd-kit/sortable';
import {Observable, ReplaySubject, take} from 'rxjs';

const lsKey = 'planningItems';

const __planningListItems: {[id: string]: PlanningListItem} = {};
let __planningItems: PlanningItem[] = [];

const idx: (id: string) => number = id =>
  __planningItems.findIndex(i => id === i.id);

const planningItem$ = new ReplaySubject<PlanningItem[]>(1);
const planningListStateSubj = new ReplaySubject<PlanningListState>(1);

loadPlanningItems()
  .pipe(take(1))
  .subscribe(items => {
    __planningItems = items;
  });

const planningService: PlanningService = {
  add(id, libraryId, color) {
    if (__planningItems.some(item => item.id === id)) {
      return false;
    }

    __planningItems.push({
      id,
      libraryId,
      color,
      visible: true,
      notify: true
    });

    savePlanningItems(__planningItems);
    updatePlanningListState(__planningItems);
    return true;
  },
  remove(id: string) {
    savePlanningItems(__planningItems, `${lsKey}__${new Date().toISOString()}`);
    __planningItems = __planningItems.filter(i => i.id !== id);
    delete __planningListItems[id];

    savePlanningItems(__planningItems);
    updatePlanningListState(__planningItems);
    return this.planningList();
  },

  moveItemAboveX(movedItemId: string, xId: string) {
    const oldIndex = idx(movedItemId);
    const newIndex = idx(xId);

    __planningItems = arrayMove<PlanningItem>(
      __planningItems,
      oldIndex,
      newIndex
    );
    savePlanningItems(__planningItems);
    return this.planningList();
  },

  changeVisibilityState(id: string, visible: boolean) {
    __planningItems[idx(id)].visible = visible;
    __planningListItems[id].visible = visible;
    savePlanningItems(__planningItems);
    updatePlanningListState(__planningItems);
    return this.planningList();
  },

  planningList() {
    return __planningItems.map<PlanningListItem>(item => {
      if (!__planningListItems[item.id]) {
        const module = require(`../data/books/${item.id}.json`).toc;

        const bookIcon = (
          libraryMappings.books.find(b => b.id === item.id) || {
            bookIcon: 'capital-X.svg'
          }
        ).bookIcon;

        __planningListItems[item.id] = {
          id: item.id,
          bookIcon,
          color: item.color,
          visible: item.visible,
          name: module.name,
          superTopic: module.filters.supertopic[0],
          libraryId: item.libraryId
        };
      }

      return __planningListItems[item.id];
    });
  },

  itemIsPlanned(id: string): boolean {
    return __planningItems.some(item => item.id === id);
  },

  markAsRead() {
    __planningItems.forEach(item => {
      item.notify = false;
    });
    savePlanningItems(__planningItems);
    updatePlanningListState(__planningItems);
  },

  get plState$() {
    return planningListStateSubj.asObservable();
  }
};

const PlanningServiceContext: Context<PlanningService> =
  createContext(planningService);

export const usePlanning = () => useContext(PlanningServiceContext);
// export const PlanningServiceProvider: React.FC<PlanningService> = ({
//   children,
//   ...apiMethods
// }) => {
//   const api = {
//     set: apiMethods.planningItems() || set,
//     get: apiMethods.get || get
//   };
//   return (
//     <PtVersionContext.Provider value={api}>
//       {children}
//     </PtVersionContext.Provider>
//   );
// };

function loadPlanningItems(key = lsKey): Observable<PlanningItem[]> {
  const items: PlanningItem[] =
    StorageService.load<PlanningItem[]>(key, false) || [];

  planningItem$.next(items);
  savePlanningItems(items);
  updatePlanningListState(items);
  return planningItem$.asObservable();
}

function savePlanningItems(pI: Readonly<PlanningItem[]>, key = lsKey) {
  StorageService.save(key, pI, false);
}

function updatePlanningListState(pl: Readonly<PlanningItem[]>) {
  planningListStateSubj.next({
    total: pl.length,
    visible: pl.filter(i => i.visible).length,
    notify: pl.filter(i => i.visible && i.notify).length
  });
}
