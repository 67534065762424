import styled, {css} from 'styled-components/macro';
import {PlanningItemProps} from './types';
import hexToRgba from 'hex-to-rgba';
import {colors, dimensions} from '@bettermarks/bm-ui-components/';

export const buttonCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: none;
  padding: 12px;
  height: 100%;
`;

export const ActionButton = styled.button`
  ${buttonCss};
`;
export const DragButton = styled.button`
  ${buttonCss};
  cursor: move;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const Details = styled(Column)`
  flex: 1 1 auto;
  justify-content: center;
  margin: ${dimensions.spaceM};
`;

export const Topic = styled.h6<Pick<PlanningItemProps, 'visible'>>`
  font-weight: 400;
  font-size: ${dimensions.fontSizeM};
  font-style: ${p => (p.visible ? 'normal' : 'italic')};
  color: ${colors.cGray800};
  margin: 0;
`;

export const SuperTopic = styled.span`
  font-size: ${dimensions.fontSizeS};
  color: ${colors.cGray600};
`;

export const Icon = styled.span<Pick<PlanningItemProps, 'color' | 'visible'>>`
  flex: 0 0 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    p.color ? hexToRgba(p.color, 0.2) : colors.cGray400};
  img {
    width: 45px;
    height: 45px;
  }

  transition: all 0.25s ease-in-out;
`;

export const Actions = styled(Row)`
  flex: 0 0 auto;
  height: 100%;
`;
