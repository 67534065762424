import {
  BmProductionFilter,
  BmProductionLibrary,
  Book,
  ColorAndIconMapping,
  FilterUpdatedEvent,
  Library,
  LibraryFilterProps,
  LibraryGroup,
  LibraryServiceApi,
  Module,
  SuperTopic
} from '../types';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import prodLibrary from '../data/libraries/bm-library-production.json';
import libraryMappings from '../data/libraries/color-and-icon-mappings.json';
import {libraryUtils} from './library.utils';
import React, {Context, createContext, useContext} from 'react';
import {ModuleDetailProps, TileImageItem} from '@bettermarks/bm-ui-components';
import {PtVersion} from './pt-storage.service';
import LibraryV1 from '../data/libraries/libraries.v1.json';
import LibraryV2 from '../data/libraries/libraries.v2.json';

export const LibraryService: LibraryServiceApi = publicApi();
// creating context
const LibraryContext: Context<LibraryServiceApi> =
  createContext(LibraryService);

const combinedLibGroups = [...LibraryV1, ...LibraryV2];

export const useLibrary = () => useContext(LibraryContext);
export const LibraryProvider: React.FC<Partial<LibraryServiceApi>> = ({
  children,
  ...apiMethods
}) => {
  const api = {
    library$: apiMethods.library$ || LibraryService.library$,
    libraryFilter$: apiMethods.libraryFilter$ || LibraryService.libraryFilter$,
    onFilterChanged:
      apiMethods.onFilterChanged || LibraryService.onFilterChanged,
    emptyFilter: apiMethods.emptyFilter || LibraryService.emptyFilter,
    chapterIntro: apiMethods.chapterIntro || LibraryService.chapterIntro,
    getModule: apiMethods.getModule || LibraryService.getModule,
    getPreviewExerciseList:
      apiMethods.getPreviewExerciseList ||
      LibraryService.getPreviewExerciseList,
    getLibraryName: apiMethods.getLibraryName || LibraryService.getLibraryName,
    getDomainForModule:
      apiMethods.getDomainForModule || LibraryService.getDomainForModule
  };
  return (
    <LibraryContext.Provider value={api}>{children}</LibraryContext.Provider>
  );
};

function publicApi(): LibraryServiceApi {
  let libraryFilter: LibraryFilterProps;
  let libraryView: ReplaySubject<Library>;

  const {
    getFilter,
    sanitizeLabel,
    activeFilterButton,
    initialFilterState,
    handleFilterChanges,
    initFilters,
    emptyFilter
  } = libraryUtils;

  const libraryFilterSubj: Readonly<BehaviorSubject<LibraryFilterProps>> =
    new BehaviorSubject(initialFilterState());

  const mapBmProdToTC4Lib = (
    mappings: ColorAndIconMapping,
    lib: BmProductionLibrary
  ): {library: Library; enrichedBooks: Book[]} => {
    const superTopics: BmProductionFilter['values'] = getFilter(
      lib,
      'supertopic'
    ).values;

    const books = lib.books.map(book => ({
      ...book,
      ...{
        filters: {
          supertopic: book.filters.supertopic.map(st => sanitizeLabel(st)),
          classlevel: book.filters.classlevel
        }
      },
      ...mappings.books.find(b => b.id === book.id)
    }));

    return {
      library: [...mappings.domains].map(domain => ({
        ...domain,
        ...{name: sanitizeLabel(domain.name)},
        superTopics: superTopics
          .filter(st => st.parent === domain.name)
          .map<SuperTopic>(stFromFilter => ({
            name: sanitizeLabel(stFromFilter.label),
            color: (
              mappings.superTopics.find(s => s.name === stFromFilter.label) || {
                color: ''
              }
            ).color,
            books: []
          }))
      })),
      enrichedBooks: books
    };
  };

  return {
    library$(version: PtVersion, libraryId: string = ''): Observable<Library> {
      if (!libraryView) {
        libraryView = new ReplaySubject<Library>(1);
        // later down the road: actually load library
        const {library, enrichedBooks} = mapBmProdToTC4Lib(
          libraryMappings,
          prodLibrary
        );
        libraryFilter = initFilters(prodLibrary, enrichedBooks);
        handleFilterChanges(
          libraryFilterSubj,
          libraryView,
          library,
          enrichedBooks
        );

        libraryFilterSubj.next({...libraryFilter});
      }

      if (version === PtVersion.LibraryGroupsPerClass) {
        const classLevel = libraryId.split('-').pop();
        const fItem = libraryFilter.primary.values.find(
          f => f.label === classLevel
        );
        fItem &&
          LibraryService.onFilterChanged({
            type: 'primary',
            updatedItem: fItem
          });
      }

      return libraryView.asObservable();
    },
    libraryFilter$(): Observable<LibraryFilterProps> {
      return libraryFilterSubj.asObservable();
    },
    onFilterChanged(ev: FilterUpdatedEvent): void {
      libraryFilter[ev.type].values.forEach(item => {
        item.active =
          item.label === ev.updatedItem.label ? !item.active : false;
      });

      libraryFilter.activeFilters = activeFilterButton(libraryFilter);

      libraryFilter[
        (ev.type === 'primary' && 'secondary') || 'primary'
      ].values.forEach(item => {
        item.disabled = !item.itemsPerFilterCombination.get(
          (ev.updatedItem.active && ev.updatedItem.label) || '__SELF'
        );
      });

      libraryFilterSubj.next(libraryFilter);
    },
    chapterIntro(bookId: string): ModuleDetailProps {
      const chapterIntro = require(`../data/chapter_intros/${bookId}.json`);
      const imageList = require(`../data/books/${bookId}--examples.json`);
      return {
        preknowledge:
          (chapterIntro.preKnowledge &&
            chapterIntro.preKnowledge.map((label: string) => ({label}))) ||
          [],
        objectives:
          (chapterIntro.postKnowledge &&
            chapterIntro.postKnowledge.map((label: string) => ({label}))) ||
          [],
        examples:
          (imageList.preview_list &&
            imageList.preview_list.map((image: string) => ({
              image: image.replace('/static', '')
            }))) ||
          []
      };
    },
    getModule(moduleId: string): Module {
      return require(`../data/books/${moduleId}.json`).toc;
    },
    getPreviewExerciseList(contentListRef: string): TileImageItem[] {
      let previewImages: TileImageItem[] = [];
      try {
        previewImages =
          require(`../data/previews/${contentListRef}.json`).preview_list.map(
            (image: string) => ({image: image.replace('/static', '')})
          );
      } catch (e: any) {
        window.console.log(e);
      }

      return previewImages;
    },
    getLibraryName(id: string) {
      return (
        combinedLibGroups
          .flatMap((libGroup: LibraryGroup) => libGroup.content)
          .find(lib => lib.id === id) || {title: 'Mathematik'}
      ).title;
    },
    emptyFilter,
    getDomainForModule(library, module) {
      return library.find(domain =>
        domain.superTopics.some(st =>
          module.filters.supertopic
            .map(st => libraryUtils.sanitizeLabel(st))
            .includes(st.name)
        )
      );
    }
  };
}
