import React from 'react';

export const CheckmarkIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 14}
    height={height || 10}
    viewBox='0 0 14 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 1C14 1.28125 13.875 1.53125 13.6875 1.71875L5.6875 9.71875C5.5 9.90625 5.25 10 5 10C4.71875 10 4.46875 9.90625 4.28125 9.71875L0.28125 5.71875C0.09375 5.53125 0 5.28125 0 5C0 4.4375 0.4375 4 1 4C1.25 4 1.5 4.125 1.6875 4.3125L5 7.59375L12.2812 0.3125C12.4688 0.125 12.7188 0 13 0C13.5312 0 14 0.4375 14 1Z'
      fill={fill || 'white'}
    />
  </svg>
);
