import styled, {css} from 'styled-components';

import {
  dimensions,
  StyledFC,
  Knob,
  KnobType,
  IconBtnStyle
} from '@bettermarks/bm-ui-components';
import {ContentType} from '../Lob/Lob';
import {CloseIcon} from '../Icons/Close';

interface DetailHeaderProps {
  modalTitle?: any;
  contentType: boolean;
  onClose: () => void;
  closeFixed?: boolean;
  rightPosition?: number;
}

const _DetailHeader: StyledFC<DetailHeaderProps> = ({
  className,
  modalTitle,
  contentType = true,
  onClose
}) => {
  return (
    <header className={className}>
      {modalTitle && contentType ? (
        <ContentType activityType={modalTitle} label useSmallIcons />
      ) : (
        modalTitle
      )}

      <Knob
        type={KnobType.ghost}
        iconBtnStyle={IconBtnStyle.fill}
        iconAppended={<CloseIcon />}
        onClick={onClose}
      />
    </header>
  );
};

export const DetailHeader = styled(_DetailHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.spaceS};
  position: relative;
  /* border-bottom: 1px solid #dddddd; */
  min-height: 52px;
  ${Knob} {
    ${p =>
      p.closeFixed
        ? css`
            position: fixed;

            @media (min-width: 768px) {
              top: 194px;
            }
            @media (max-width: 767px) {
              top: 12px;
            }
            @media (min-width: 1401px) {
              right: ${p.rightPosition + 'px'} !important;
            }
            @media (max-width: 1400px) {
              right: 16px;
            }
          `
        : css`
            position: absolute;
            top: ${dimensions.spaceS};
          `}

    right: ${dimensions.spaceS};
  }
`;
