import styled, {css} from 'styled-components';
import {StyledFC, dimensions, colors} from '@bettermarks/bm-ui-components';
import {CheckmarkIcon} from './Checkmark';

export interface Disableable {
  disabled?: boolean;
}

export interface CheckRectProps {
  checked?: boolean;
  small?: boolean;
}

export interface CheckBoxProps {
  onClick?: () => void;
  padding?: string;
}

const Transition = css`
  transition: all 0.25s ease-in-out;
`;

const Label = styled.div<Disableable>`
  color: ${p => (p.disabled ? colors.cGray200 : colors.cGray800)};
  ${Transition}
`;
Label.displayName = 'Label';

const CheckMark = styled.span<Disableable>`
  display: inline-block;
  width: ${dimensions.spaceS};
  height: ${dimensions.spaceS};
  transform: rotate(-135deg);
  position: absolute;
  top: -4px;
  left: -1px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${p => (p.disabled ? colors.cGray200 : 'white')};
  }
  &:before {
    width: 6px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 10px;
  }
`;
CheckMark.displayName = 'CheckMark';

const CheckRect = styled.span<CheckRectProps & Disableable>`
  width: ${p => (p.small ? dimensions.spaceM : dimensions.spaceXl)};
  height: ${p => (p.small ? dimensions.spaceM : dimensions.spaceXl)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${dimensions.borderWidthS} solid transparent;
  border-radius: ${dimensions.borderRadiusM};
  // margin: ${dimensions.fontSizeS} ${dimensions.spaceS};
  position: relative;
  ${Transition}
  ${p =>
    p.checked
      ? css`
          background: ${colors.cBlue700};
          border-color: ${colors.cBlue700};
        `
      : css`
          background: transparent;
          border-color: ${colors.cBlue700};
        `}

  ${p =>
    p.disabled &&
    css`
      border-color: ${colors.cGray200};
      background: white;
    `}
    ${p =>
    p.disabled &&
    p.checked &&
    css`
      background: ${colors.cGray200};
      ${CheckMark} {
        &:before,
        &:after {
          background: white;
        }
      }
    `}
`;

const _CheckBox: StyledFC<CheckBoxProps & CheckRectProps & Disableable> = ({
  children,
  dataCy,
  checked,
  disabled,
  className,
  small,
  onClick
}) => {
  return (
    <div
      className={className}
      onClick={disabled ? undefined : onClick}
      data-cy={dataCy}
    >
      <CheckRect small={small} checked={checked} disabled={disabled}>
        {checked && (
          <CheckmarkIcon width={small ? 12 : 14} height={small ? 6 : 10} />
        )}
      </CheckRect>
      {children && <Label disabled={disabled}>{children}</Label>}
    </div>
  );
};

export const CheckBox = styled(_CheckBox)`
  display: flex;
  align-items: center;
  min-height: ${dimensions.minClickableHeight};
  user-select: none;
  cursor: pointer;
  padding: ${p => p.padding || '0'};
`;

CheckBox.displayName = 'CheckBox';
