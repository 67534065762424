export enum Variant {
  solid = 'solid',
  outline = 'outline',
  ghost = 'ghost'
}

export interface MetaLabelProps {
  variant: Variant | string;
  label: string;
}
