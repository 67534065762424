import React from 'react';
import styled from 'styled-components';
import {
  StyledFC,
  Renderer,
  StickDirection,
  UniversalTooltip,
  dimensions,
  colors,
  CaretDownIcon,
  KnobType,
  Knob
} from '@bettermarks/bm-ui-components';

import {NavigationMenuItemProps, NavigationMenuItemType} from './types';
import {SignOutIcon} from '../Icons';

interface ToggleProps {
  toggle: () => void;
}

interface DropdownTriggerProps extends ToggleProps {
  width?: number;
}

const _DropdownTrigger: StyledFC<DropdownTriggerProps> = ({
  children,
  className,
  toggle
}) => {
  return (
    <button onClick={toggle} className={className}>
      {children} <CaretDownIcon />
    </button>
  );
};

const DropdownTrigger = styled(_DropdownTrigger)`
  font-family: inherit;
  font-size: ${dimensions.fontSizeS};
  color: ${colors.cBlue700};
  border-radius: ${dimensions.borderRadiusM};
  padding: 8.5px ${dimensions.spaceS};
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: none;
  background: transparent;
  height: 40px;
  cursor: pointer;

  & svg {
    margin-left: ${dimensions.spaceXs};
  }

  path, polygon {
    fill ${colors.cBlue700};
  }

  &:hover {
    background: ${colors.cBlue100};
  }
`;

const IconBox = styled.span`
  display: flex;
  align-items: center;
  flex: 0 0 ${dimensions.spaceL};
  margin-right: ${dimensions.spaceS};
`;

const LabelBox = styled.span`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  max-width: 100%;
`;

const _NavMenuItem: StyledFC<NavigationMenuItemProps> = ({
  className,
  label,
  icon,
  url
}) => {
  return (
    <a href={url} className={className}>
      {icon && <IconBox>{icon}</IconBox>}
      <LabelBox>{label}</LabelBox>
    </a>
  );
};

export const NavMenuItem = styled(_NavMenuItem)`
  display: flex;
  padding: ${dimensions.spaceS} ${dimensions.spaceM};
  line-height: ${dimensions.lineHeightM};
  color: ${p => (p.url ? colors.cBlue700 : undefined)};
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${p => (p.url ? colors.cBlue100 : undefined)};
  }
`;

export interface NavigationMenuContentProps extends ToggleProps {
  items: NavigationMenuItemType[];
  label: string;
  maxChars?: number;
  width?: number;
}

const FullLabelMenuItem = styled.li`
  display: inline-flex;
  word-break: break-word;
  padding: ${dimensions.spaceM} ${dimensions.spaceM} 0;
  color: ${colors.cGray700};
`;

export const truncate = (
  input: string,
  maxLength = Infinity
): string | false => {
  if (!maxLength || maxLength < 0) return false;

  return maxLength >= input.length
    ? false
    : input.substring(0, maxLength - 1) + '…';
};

const _NavigationMenuContent: StyledFC<NavigationMenuContentProps> = ({
  className,
  items,
  label,
  maxChars,
  dataCy = 'NavigationMenuContent',
  toggle
}) => {
  const truncated = truncate(label, maxChars);
  return (
    <ul className={className} onClick={toggle} data-cy={dataCy}>
      {truncated && <FullLabelMenuItem>{label}</FullLabelMenuItem>}
      {items.map((item, i) => (
        <li key={i} style={item.itemStyle}>
          {'render' in item ? (
            item.render
          ) : (
            <NavMenuItem url={item.url} icon={item.icon} label={item.label} />
          )}
        </li>
      ))}
    </ul>
  );
};

const _LogoutButton: StyledFC<{label: string}> = ({className, label}) => (
  <Knob
    className={className}
    type={KnobType.button}
    iconPrepended={<SignOutIcon />}
    onClick={() => window.alert('logout')}
    label={label}
    stretch
  />
);

export const LogoutButton = styled(_LogoutButton)`
  flex: 1;
`;

export const NavigationMenuContent = styled(_NavigationMenuContent)`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: ${p => (p.width ? p.width + 'px' : 'max-content')};
  list-style: none;

  li:last-child ${NavMenuItem} {
    border-bottom-left-radius: ${dimensions.borderRadiusM};
    border-bottom-right-radius: ${dimensions.borderRadiusM};
  }

  li:not(:first-child) {
    box-shadow: inset 0 1px 0 ${colors.cGray500};
  }

  li {
    display: flex;
    flex: 1;
  }
`;

export interface NavigationMenuProps {
  label: string;
  maxChars?: number;
  renderContent?: Renderer;
  width?: number;
}

export const NavigationMenu: StyledFC<NavigationMenuProps> = ({
  dataCy = 'NavigationMenu',
  label,
  renderContent,
  maxChars
}) => {
  const truncated = truncate(label, maxChars);
  return (
    <UniversalTooltip
      dataCy={dataCy}
      renderTrigger={toggle => (
        <DropdownTrigger toggle={toggle}>{label}</DropdownTrigger>
      )}
      zIndex={99}
      contentStickTo={StickDirection.right}
    />
  );
};

export const NavigationMenuSpacing = styled.div`
  display: flex;
  align-items: center;
  gap: ${dimensions.spaceXl};
  height: 100%;
`;
