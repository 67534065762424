import {
  colors,
  Drawer,
  OptionSwitcher,
  StyledFC
} from '@bettermarks/bm-ui-components';
import styled from 'styled-components/macro';
import {FilterItem, LibraryFilterProps} from '../../types';
import {useCallback, useEffect, useState} from 'react';
import {ScrollFenceEvent, useScrolling} from '../../services/scrolling.service';

const _LibraryFilter: StyledFC<LibraryFilterProps> = ({
  primary,
  secondary,
  activeFilters,
  filterChange
}) => {
  const onPrimaryFilterItemClick = useCallback(
    (item: FilterItem) =>
      filterChange && filterChange({type: 'primary', updatedItem: item}),
    []
  );

  const onSecondaryFilterItemClick = useCallback(
    (item: FilterItem) =>
      filterChange && filterChange({type: 'secondary', updatedItem: item}),
    []
  );

  const [filterIsExpanded, setFilterState] = useState<boolean>(true);

  const ScrollingService = useScrolling();
  useEffect(() => {
    const s = ScrollingService.registerScrollFence$('library', {
      top: 3
    }).subscribe((sfe: ScrollFenceEvent) =>
      setFilterState(sfe !== ScrollFenceEvent.ENTERED)
    );
    return () => s.unsubscribe();
  }, []);

  return primary.values.length && secondary.values.length ? (
    <Drawer
      buttonLabel={activeFilters}
      isActive={filterIsExpanded}
      onClose={() => setFilterState(false)}
      onExpand={() => setFilterState(true)}
    >
      <OptionSwitcher
        optionDefault={primary.default}
        optionItems={primary.values}
        onClick={onPrimaryFilterItemClick}
        border
        spacing='6px'
      />
      <OptionSwitcher
        optionDefault={secondary.default}
        optionItems={secondary.values}
        onClick={onSecondaryFilterItemClick}
      />
    </Drawer>
  ) : (
    <></>
  );
};
export const LibraryFilter = styled(_LibraryFilter)`
  ${Drawer} ${OptionSwitcher}::last-child {
    margin-top: 6px;
  }

  background: ${colors.cWhite};
`;
