import {get, compose} from 'lodash/fp';

export const getDataCy = get('dataCy');

export const setDataCy = (dataCy?: string) =>
  dataCy ? {'data-cy': dataCy} : null;

/**
 * Adds an optional `dataCy` property to a styled component props.
 * The `dataCy` attribute will only be rendered when it is truthy.
 *
 * Usage:
 * `styled.div.attrs(addDataCy)`
 *
 * To add more attributes like this
 * @see addDataCyAndMergeWith
 * @see addDataCyAndMergePropsAttrs
 */
export const addDataCy = compose(setDataCy, getDataCy);

/**
 * Adds an optional `dataCy` property and other fixed attributes to a styled component props.
 * The `dataCy` attribute will only be rendered when it is truthy.
 *
 * Usage:
 * `styled.div.attrs(addDataCyAndMergeWith({prop: "value"}))`
 *
 * @see addDataCy
 * @see addDataCyAndMergePropsAttrs
 */
export const addDataCyAndMergeWith = (toMerge: any) => (props: any) => ({
  ...toMerge,
  ...addDataCy(props)
});

/**
 * Adds an optional `dataCy` property and `ReturnType<mergeFunc>` to a styled component props.
 * The `dataCy` attribute will only be rendered when it is truthy.
 *
 * Usage:
 * ```
 * styled.div.attrs(addDataCyAndMergePropsAttrs(
 *   (props: MyProps) => {
 *     //do fancy things with the props
 *     return modifiedProps;
 *   }
 * ))
 * ```
 *
 * @see addDataCy
 * @see addDataCyAndMergeWith
 */
export const addDataCyAndMergePropsAttrs =
  <P extends unknown>(mergeFunc: (props: P) => any) =>
  (props: P) => ({
    ...mergeFunc(props),
    ...addDataCy(props)
  });
