import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const KemListIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 28}
    height={height || 28}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.5895 4.23768V0H21.9309L21.6201 0.00700114C18.7015 0.137278 15.3329 2.07414 13.6672 4.84962L13.5419 5.0675L13.2018 4.8726C11.5521 3.97738 9.42459 3.49578 7.22799 3.49578C4.59368 3.49578 2.26601 4.18536 0.29659 5.48116L0 5.67631V27.9138L1.02582 27.2247L1.39958 26.983C3.15459 25.8918 5.06324 25.3154 7.22799 25.3154C9.66443 25.3154 11.8488 25.9627 13.386 27.1923L13.7369 27.473L14.1263 27.2486L14.5459 27.0144C16.5146 25.9516 18.6215 25.3154 20.6796 25.3154C22.924 25.3154 24.9011 25.9675 26.5395 27.2038L27.5948 28V5.95858L26.8774 5.56133C25.4994 4.82794 24.3202 4.43433 22.822 4.26113L22.5895 4.23768ZM21.0759 1.37798L21.271 1.35319V19.8621L21.093 19.8966C18.4003 20.4537 16.3254 21.9382 14.4561 24.48V6.27973H14.4074L14.5332 6.00821C15.7366 3.5708 18.5614 1.73228 21.0759 1.37798ZM13.1389 6.37025C11.6566 5.37496 9.49183 4.81176 7.22799 4.81176C5.00822 4.81176 3.052 5.34745 1.37554 6.3568L1.31717 6.3927V25.5049L1.47724 25.4176C3.08986 24.5599 4.8318 24.0752 6.74586 24.0058L7.22799 23.9971C9.43797 23.9971 11.4873 24.4822 13.1389 25.4346V6.37025ZM22.5895 5.56197V20.9954L22.0216 21.0743C19.4789 21.4276 17.5471 22.6377 15.7807 24.9125L15.6967 25.0206L15.8005 24.9792C17.2308 24.4168 18.7087 24.0726 20.1869 24.0094L20.6796 23.9988C22.576 23.9988 24.3191 24.4173 25.8605 25.2214L26.2776 25.4526V6.73557L26.2426 6.7161C25.0271 6.07061 24.0129 5.72979 22.7112 5.57482L22.5895 5.56197Z'
      fill={fill || colors.cGray700}
    />
  </svg>
);
