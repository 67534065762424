import styled from 'styled-components';
import {colors, dimensions, StyledFC} from '@bettermarks/bm-ui-components';
import {RequireAtLeastOne} from '../types';
import {NavLink as _RouterLink, useLocation} from 'react-router-dom';
import {css} from 'styled-components/macro';
import {useEffect, useState} from 'react';

interface NavigationProps {
  menuItems: NavigationItemProps[];
}

export type NavigationItemProps = RequireAtLeastOne<
  {
    url: string;
    icon: any;
    label: string;
    active?: string;
    onClick?: () => void;
    badge?: any;
  },
  'url' | 'onClick'
>;

const compareUrl = (currPath: string, navLink: string) =>
  navLink
    .split('/')
    .filter(Boolean)[0]
    .startsWith(currPath.split('/').filter(Boolean)[0]);

const _NavigationItem: StyledFC<NavigationItemProps> = ({
  icon,
  label,
  url,
  onClick,
  badge
}) => {
  const location = useLocation();
  const [cssClass, setClass] = useState<string>('');

  useEffect(() => {
    setClass(url && compareUrl(location.pathname, url) ? 'isActive' : '');
  }, [location.pathname]);
  return (
    <>
      {(url && (
        <RouterLink className={cssClass} to={url}>
          <NavIcon>{icon}</NavIcon>
          <NavLabel>{label}</NavLabel>
          {badge}
        </RouterLink>
      )) || (
        <Item {...((onClick && {onClick}) || {})}>
          <NavIcon>{icon}</NavIcon>
          <NavLabel>{label}</NavLabel>
        </Item>
      )}
    </>
  );
};

const navigationStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${dimensions.spaceXs} ${dimensions.spaceL};
  color: ${colors.cBlue700};
  cursor: pointer;
  font-size: ${dimensions.fontSizeS};
  position: relative;
`;

const Item = styled.a`
  ${navigationStyle}
`;

const RouterLink = styled(_RouterLink)`
  ${navigationStyle};
  text-decoration: none;
  &.isActive {
    background: ${colors.cBlue200};
  }
`;

const NavigationItem = styled(_NavigationItem)`
  ${p => (!p.url ? navigationStyle : 'list-style: none;')};
  background: ${p => (p.active ? colors.cBlue200 : 'transparent')};
  position: relative;
`;

const NavIcon = styled.span`
  flex: 1 0 auto;
`;
const NavLabel = styled.span`
  flex: 0;
`;

const _Navigation: StyledFC<NavigationProps> = ({className, menuItems}) => {
  return (
    <nav className={className}>
      {menuItems.map((item: NavigationItemProps) => (
        <NavigationItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          active={item.active}
          onClick={item.onClick}
          url={item.url}
          {...((item.badge && {badge: item.badge}) || {})}
        />
      ))}
    </nav>
  );
};

export const Navigation = styled(_Navigation)`
  display: flex;
  align-items: stretch;
  height: 100%;
  margin: 0;
  padding: 0;
`;
