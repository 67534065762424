import React from 'react';

export const ListIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='13'
    height='11'
    viewBox='0 0 13 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 1.5H0V0.5H2V1.5ZM0 4.5H2V3.5H0V4.5ZM0 7.5H2V6.5H0V7.5ZM0 10.5H2V9.5H0V10.5ZM13 7.5H3V6.5H13V7.5ZM3 4.5H13V3.5H3V4.5ZM13 1.5H3V0.5H13V1.5ZM3 10.5H13V9.5H3V10.5Z'
      fill='#1188EE'
    />
  </svg>
);
