import React, {memo, useEffect, useState} from 'react';
import {SearchBar} from '../../components/SearchBar';
import {TileGrid} from './TileGrid';
import {FilterUpdatedEvent, Library, LibraryFilterProps} from '../../types';
import {LibraryFilter} from './LibraryFilter';
import {zip} from 'rxjs';
import {useOverlay} from '../../components/Overlay';
import {useLibrary} from '../../services/library.service';
import {
  Group,
  Layout,
  LayoutComponentService
} from '../../components/LayoutComponents';
import {PtVersion, useStorage} from '../../services/pt-storage.service';
import {useLocation, useParams} from 'react-router-dom';
import {useScrolling} from '../../services/scrolling.service';
import {RouteService} from '../../services/route.service';
import {WhiteboardTeaser} from './WhiteboardTeaser';

export const LibraryPage: React.FC = () => {
  const LibraryService = useLibrary();
  const OverlayService = useOverlay();
  const StorageService = useStorage();
  const ScrollingService = useScrolling();
  const location = useLocation();

  const {libraryId} = {libraryId: '', ...useParams<{libraryId: string}>()};
  const [library, updateLibraryView] = useState<Library>([]);
  const [libraryFilter, updateLibraryFilter] = useState<LibraryFilterProps>({
    primary: LibraryService.emptyFilter(),
    secondary: LibraryService.emptyFilter(),
    activeFilters: ''
  });

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [libraryName, setLibraryName] = useState<string>('');

  const {primary, secondary, activeFilters} = libraryFilter;

  const onFilterChange = (ev: FilterUpdatedEvent) => {
    OverlayService.showAndFadeOut();
    LibraryService.onFilterChanged(ev);
    ScrollingService.resetScrollPos();
  };

  const MemoizedFilter = memo(LibraryFilter);
  const MemoizedSearch = memo(SearchBar);

  useEffect(() => {
    setShowFilter(
      StorageService.getVersion() === PtVersion.LibraryGroupsPerSubject
    );

    setLibraryName(LibraryService.getLibraryName(libraryId));

    const subscription = zip(
      LibraryService.library$(StorageService.getVersion(), libraryId),
      LibraryService.libraryFilter$()
    ).subscribe(([lib, filter]) => {
      updateLibraryFilter(filter);
      updateLibraryView(lib);
    });

    StorageService.saveSelectedLibrary(libraryId);
    RouteService.setContentRoute(
      RouteService.librarySelection(StorageService.getVersion())
    );
    StorageService.save('contentLinkOnPlanningPage', location.pathname, false);

    return () => {
      subscription.unsubscribe();
    };
  }, [libraryId]);

  const Search = (
    <MemoizedSearch
      key={'lib-search-bar'}
      isSelectionPage={false}
      libraryName={libraryName}
    />
  );

  const Filter = (
    <MemoizedFilter
      key={'filter'}
      primary={primary}
      secondary={secondary}
      activeFilters={activeFilters}
      filterChange={onFilterChange}
    />
  );

  useEffect(() => {
    if (libraryName) {
      const header = [Search];
      showFilter && header.push(Filter);
      LayoutComponentService.header = header;
    }
  }, [libraryName, showFilter, activeFilters]);

  return (
    <Layout paddingBottom>
      <TileGrid
        library={library}
        selectedLibrary={libraryName}
        showDomainBanner={showFilter}
      />
      {libraryName?.includes('5') || libraryName?.includes('6') ? (
        <Group style={{marginTop: '48px'}}>
          <WhiteboardTeaser />
        </Group>
      ) : (
        ''
      )}
    </Layout>
  );
};
