import styled from 'styled-components/macro';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {BehaviorSubject, Observable, Subject, take} from 'rxjs';

export interface OverlayService {
  showAndFadeOut: () => void;
  fadeIn: (onClose?: () => void) => void;
  deactivate: () => void;
  click$: () => Observable<void>;
  triggerClick: () => void;
}

export type OverlayClass = 'active' | 'permanent' | undefined;
const animationDuration = 600;

let currentActiveState: OverlayClass;

const activeStateSubj = new BehaviorSubject<OverlayClass>(undefined);

const clickSubj = new Subject<void>();
const setOverlayClassTo: (newClass?: OverlayClass) => void = newClass => {
  currentActiveState = newClass;
  activeStateSubj.next(newClass);
};

const OverlayServiceApi: OverlayService = {
  click$() {
    return clickSubj.asObservable();
  },
  showAndFadeOut() {
    setOverlayClassTo('active');
    setTimeout(this.deactivate, animationDuration);
  },
  fadeIn(onClose) {
    setOverlayClassTo('permanent');
    onClose && this.click$().pipe(take(1)).subscribe(onClose);
  },
  deactivate() {
    setOverlayClassTo();
  },
  triggerClick() {
    clickSubj.next();
  }
};

const OverlayContext: React.Context<OverlayService> =
  createContext(OverlayServiceApi);

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider: React.FC<Partial<OverlayService>> = ({
  children,
  ...api
}) => {
  const apiMethods: OverlayService = {
    showAndFadeOut: api.showAndFadeOut || OverlayServiceApi.showAndFadeOut,
    fadeIn: api.fadeIn || OverlayServiceApi.fadeIn,
    deactivate: api.deactivate || OverlayServiceApi.deactivate,
    click$: api.click$ || OverlayServiceApi.click$,
    triggerClick: api.triggerClick || OverlayServiceApi.triggerClick
  };

  return (
    <OverlayContext.Provider value={apiMethods}>
      {children}
    </OverlayContext.Provider>
  );
};

const OverlayContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 6;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.active {
    position: absolute;
    visibility: visible;
    animation: fadeOut ease-out ${animationDuration}ms;
  }

  &.permanent {
    position: fixed;
    visibility: visible;
    animation: fadeIn ease-out 0.25s forwards;
  }
`;

export const Overlay: React.FC = () => {
  const [overlayClass, setClass] = useState<OverlayClass>();
  useEffect(() => {
    const subScr = activeStateSubj.subscribe(setClass);
    return () => subScr.unsubscribe();
  }, []);

  return (
    <OverlayContainer
      className={overlayClass}
      onClick={() => clickSubj.next()}
    />
  );
};
