import styled, {CSSProperties} from 'styled-components/macro';
import {
  ClassesIcon,
  colors,
  dimensions,
  LibraryIcon,
  PlanIcon,
  ReportIcon,
  ToolBar,
  ToolBarType,
  WorksheetIcon,
  StyledFC,
  CaretDownIcon
} from '@bettermarks/bm-ui-components';
import {handleAlertMessage} from '../helpers/Alert';
import {Navigation, NavigationItemProps} from './Navigation';
import {useRoutes} from '../services/route.service';
import {useStorage} from '../services/pt-storage.service';
import {usePlanning} from '../services/planning.service';
import {useEffect, useState} from 'react';
import {map, pairwise, startWith} from 'rxjs';
import {PlanningListState} from '../pages/Planning/types';
import {
  LogoutButton,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItemType
} from '../components/NavigationMenu';
import {SettingsIcon, TelephoneIcon, TutorialCompactIcon} from './Icons';

const MainNav = styled(ToolBar)`
  position: relative;
  z-index: 0;
  width: auto;
  background: ${colors.cWhite};
  padding: 0 ${dimensions.spaceM} 0 0;
`;

const CounterBadgeAmount = styled.div`
  line-height: 16px;
  text-align: center;
`;

const CounterBadge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background: ${colors.cBlue700};
  color: ${colors.cWhite};
  position: absolute;
  right: 4px;
  top: 2px;
  visibility: hidden;
`;

const _DropdownTrigger: StyledFC<{width?: number}> = ({
  children,
  className
}) => {
  return (
    <button className={className}>
      {children} <CaretDownIcon />
    </button>
  );
};

const DropdownTrigger = styled(_DropdownTrigger)`
  font-family: inherit;
  font-size: ${dimensions.fontSizeS};
  color: ${colors.cBlue700};
  border-radius: ${dimensions.borderRadiusM};
  padding: 8.5px ${dimensions.spaceS};
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: none;
  background: transparent;
  height: 40px;
  cursor: pointer;

  & svg {
    margin-left: ${dimensions.spaceXs};
  }

  path, polygon {
    fill ${colors.cBlue700};
  }

  &:hover {
    background: ${colors.cBlue100};
  }
`;

export const MainNavigation = () => {
  const RouteService = useRoutes();
  const StorageService = useStorage();
  const PlanningService = usePlanning();
  const [planningCounter, setCounter] = useState<number>(-1);
  const [planningCounterStyle, setPlanningCounterStyle] = useState<{
    badge: CSSProperties;
    amount: CSSProperties;
  }>({badge: {}, amount: {}});

  const initialPlanningState: PlanningListState = {
    total: -1,
    visible: 0,
    notify: -1
  };
  const [contentLink, setContentLink] = useState<string>(
    RouteService.librarySelection(StorageService.getVersion())
  );

  const resetStyle = (to: number) => {
    setPlanningCounterStyle({
      badge: {visibility: (to === 0 && 'hidden') || 'visible'},
      amount: {}
    });
  };

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    const subscr = [
      PlanningService.plState$
        .pipe(
          startWith<PlanningListState>(initialPlanningState),
          pairwise<PlanningListState>(),
          map<[PlanningListState, PlanningListState], [number, number]>(
            ([prev, current]) => [prev.notify, current.notify]
          )
        )
        .subscribe(([from, to]) => {
          if (from >= 0 && from !== to) {
            setPlanningCounterStyle({
              badge: {
                animation: 'update 0.7s',
                visibility: 'visible'
              },
              amount: {
                animation: `${
                  (from < to && 'increase') || 'decrease'
                } 0.6s ease 0.1s`
              }
            });
            timeOutId = setTimeout(() => {
              setCounter(to);
              resetStyle(to);
            }, 700);
          } else {
            resetStyle(to);
            setCounter(to);
          }
        }),
      RouteService.contentRoute$().subscribe(setContentLink)
    ];
    return () => {
      subscr.forEach(s => s.unsubscribe());
      timeOutId && clearTimeout(timeOutId);
    };
  }, []);

  const MenuItems: NavigationItemProps[] = [
    {
      label: 'Inhalte',
      icon: <LibraryIcon />,
      url: contentLink
    },
    {
      label: 'Plan',
      icon: <PlanIcon />,
      url: '/planning',
      badge: (
        <CounterBadge style={planningCounterStyle.badge}>
          <CounterBadgeAmount style={planningCounterStyle.amount}>
            {(planningCounter > 0 && planningCounter - 1) || 0}
            <br />
            {planningCounter >= 0 && planningCounter}
            <br />
            {planningCounter + 1}
          </CounterBadgeAmount>
        </CounterBadge>
      )
    },
    {
      label: 'Auswertung',
      icon: <WorksheetIcon />,
      onClick: handleAlertMessage
    },
    {
      label: 'Aufgabenblatt',
      icon: <ReportIcon />,
      onClick: handleAlertMessage
    },
    {label: 'Klassen', icon: <ClassesIcon />, onClick: handleAlertMessage}
  ];

  const UserMenuItems: NavigationMenuItemType[] = [
    {
      render: <LogoutButton label='Logout' />,
      itemStyle: {padding: 16, boxShadow: 'none'}
    },
    {
      label: 'Einstellungen',
      icon: <SettingsIcon />,
      url: '#'
    },
    {label: 'Anleitungen', icon: <TutorialCompactIcon />, url: '#'},
    {label: '+49 30 300 244 003  ', icon: <TelephoneIcon />, url: '#'},
    {label: 'Feedback', url: '#'},
    {label: 'AGB', url: '#'},
    {label: 'Impressum', url: '#'}
  ];

  const label = 'Mein Zugang';

  return (
    <MainNav
      toolBarType={ToolBarType.Primary}
      leftArea={<Navigation menuItems={MenuItems} />}
      rightArea={<DropdownTrigger>{label}</DropdownTrigger>}
    />
  );
};
