import React, {Context, createContext, useContext} from 'react';
import {PtVersion, StorageService} from './pt-storage.service';
import {Observable, ReplaySubject} from 'rxjs';

const routesWithScrollRestauration: RegExp[] = [
  new RegExp('^/library/[a-z0-9,-]*$')
];

let currentContentRoute$: ReplaySubject<string>;

export const RouteService: RouteServiceAPI = {
  libraryRoute(id) {
    return `/library/${id}`;
  },
  moduleRoute(libId, moduleId, fromPlanning = false) {
    return `/${(fromPlanning && 'planning') || 'library'}/${libId}/${moduleId}`;
  },
  librarySelection(version: PtVersion) {
    return version === PtVersion.LibraryGroupsPerSubject
      ? '/library--select'
      : '/library--select--v2';
  },
  routeShouldRestoreScrollPos(path) {
    return routesWithScrollRestauration.some((reg: RegExp) => reg.test(path));
  },
  contentRoute$() {
    if (!currentContentRoute$) {
      currentContentRoute$ = new ReplaySubject<string>(1);
      currentContentRoute$.next(
        this.librarySelection(StorageService.getVersion())
      );
    }

    return currentContentRoute$.asObservable();
  },

  setContentRoute(route) {
    this.contentRoute$() && currentContentRoute$.next(route);
  }
};

const RouteContext: Context<RouteServiceAPI> = createContext(RouteService);

export const useRoutes = () => useContext(RouteContext);

export const RouteProvider: React.FC<Partial<RouteServiceAPI>> = ({
  children,
  ...apiMethods
}) => {
  const api: RouteServiceAPI = {
    libraryRoute: apiMethods.libraryRoute || RouteService.libraryRoute,
    moduleRoute: apiMethods.moduleRoute || RouteService.moduleRoute,
    librarySelection:
      apiMethods.librarySelection || RouteService.librarySelection,
    routeShouldRestoreScrollPos:
      apiMethods.routeShouldRestoreScrollPos ||
      RouteService.routeShouldRestoreScrollPos,
    contentRoute$: apiMethods.contentRoute$ || RouteService.contentRoute$,
    setContentRoute: apiMethods.setContentRoute || RouteService.setContentRoute
  };

  return <RouteContext.Provider value={api}>{children}</RouteContext.Provider>;
};

export interface RouteServiceAPI {
  librarySelection: (version: PtVersion) => string;
  libraryRoute: (id: string) => string;
  moduleRoute: (
    libraryId: string,
    moduleId: string,
    fromPlanning?: boolean
  ) => string;
  routeShouldRestoreScrollPos: (path: string) => boolean;
  contentRoute$: () => Observable<string>;
  setContentRoute: (route: string) => void;
}
