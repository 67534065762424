import {
  BmProductionFilter,
  BmProductionLibrary,
  Book,
  Filter,
  FilterItem,
  Library,
  LibraryFilterProps
} from '../types';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {OptionSwitcherItem} from '@bettermarks/bm-ui-components';

export const libraryUtils = {
  emptyFilter(label = ''): Filter {
    return {
      identifier: '',
      values: [],
      label,
      default: label
    };
  },
  getFilter(
    lib: BmProductionLibrary,
    id: 'supertopic' | 'classlevel'
  ): BmProductionFilter {
    return lib.filters.find(f => f.identifier === id)!;
  },
  active(values: FilterItem[]): FilterItem | undefined {
    return values.find(i => i.active);
  },
  label(item: OptionSwitcherItem | undefined, filter: Filter): string {
    return (item && `${filter.label} ${item.label}`) || filter.default!;
  },
  sanitizeLabel(l: string): string {
    return l.replace(/\d(.*?) /, '').trim();
  },
  activeFilterButton(libraryFilters: LibraryFilterProps): string {
    return `${libraryUtils.label(
      libraryUtils.active(libraryFilters.primary.values),
      libraryFilters.primary
    )}, ${libraryUtils.label(
      libraryUtils.active(libraryFilters.secondary.values),
      libraryFilters.secondary
    )}`;
  },
  bookMatchesPrimaryFilter(book: Book, primary?: OptionSwitcherItem): boolean {
    return !primary || book.filters.classlevel.includes(primary.label);
  },
  bookMatchesSecondaryFilter(book: Book, secondary?: FilterItem): boolean {
    return (
      !secondary ||
      book.filters.supertopic.some(st => secondary.children.includes(st))
    );
  },
  handleFilterChanges(
    libraryFilterSubj: Readonly<BehaviorSubject<LibraryFilterProps>>,
    libraryView: ReplaySubject<Library>,
    library: Readonly<Library>,
    enrichedBooks: Readonly<Book[]>
  ): Observable<Book[]> {
    const enrichedBook$ = new Subject<Book[]>();

    libraryFilterSubj.subscribe(filters => {
      const primary = libraryUtils.active(filters.primary.values);
      const secondary = libraryUtils.active(filters.secondary.values);
      const filteredBooks = enrichedBooks.map(
        getBookWithUpdatedFilterFlag(primary, secondary)
      );

      enrichedBook$.next(filteredBooks);

      libraryView.next(
        library.map(domain => ({
          ...domain,
          ...{
            superTopics: domain.superTopics.map(st => ({
              ...st,
              ...{
                books: filteredBooks.filter(bookShouldShow(st.name))
              }
            }))
          }
        }))
      );
    });

    return enrichedBook$;
  },
  initFilters(library: BmProductionLibrary, books: Book[]): LibraryFilterProps {
    let primary: FilterItem[];
    let secondary: FilterItem[];

    primary = libraryUtils.getFilter(library, 'classlevel').values.map(i => ({
      label: i.label,
      children: [],
      itemsPerFilterCombination: new Map<string, number>(),
      active: false
    }));

    primary.sort((a, b) => parseInt(a.label) - parseInt(b.label));

    const stFilter = libraryUtils.getFilter(library, 'supertopic');
    secondary = stFilter.values.reduce((acc: FilterItem[], curr) => {
      !acc.some(i => i.label === curr.parent) &&
        acc.push({
          label: curr.parent!,
          active: false,
          children: stFilter.values
            .filter(c => c.parent === curr.parent)
            .map(v => libraryUtils.sanitizeLabel(v.label)),
          itemsPerFilterCombination: new Map<string, number>()
        });
      return acc;
    }, []);

    secondary.sort((a, b) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0
    );

    secondary = secondary.map(item => ({
      ...item,
      ...{label: item.label.replace(/\d /, '')}
    }));

    primary.forEach(pFilterItem => {
      pFilterItem.itemsPerFilterCombination.set(
        '__SELF',
        books.filter(book =>
          libraryUtils.bookMatchesPrimaryFilter(book, pFilterItem)
        ).length
      );
      secondary.forEach(sFilterItem => {
        sFilterItem.itemsPerFilterCombination.set(
          '__SELF',
          books.filter(book =>
            libraryUtils.bookMatchesSecondaryFilter(book, sFilterItem)
          ).length
        );

        const booksMatchingCombi = books.filter(
          book =>
            libraryUtils.bookMatchesPrimaryFilter(book, pFilterItem) &&
            libraryUtils.bookMatchesSecondaryFilter(book, sFilterItem)
        ).length;

        pFilterItem.itemsPerFilterCombination.set(
          sFilterItem.label,
          booksMatchingCombi
        );

        sFilterItem.itemsPerFilterCombination.set(
          pFilterItem.label,
          booksMatchingCombi
        );
      });
    });

    return {
      primary: {
        identifier: 'classLevel',
        label: 'Klassenstufe',
        default: 'Alle Klassenstufen',
        values: primary
      },
      secondary: {
        identifier: 'domain',
        label: 'Thema',
        default: 'Alle Themengebiete',
        values: secondary
      },
      activeFilters: 'Alle Klassenstufen, alle Themengebiete'
    };
  },
  initialFilterState() {
    return {
      primary: libraryUtils.emptyFilter('Alle Klassenstufen'),
      secondary: libraryUtils.emptyFilter('Alle Themengebiete'),
      activeFilters: 'Alle Klassenstufen, alle Themengebiete'
    };
  }
};

function getBookWithUpdatedFilterFlag(
  primary?: FilterItem,
  secondary?: FilterItem
): (book: Readonly<Book>) => Book {
  return (book: Readonly<Book>): Book => ({
    ...book,
    ...{
      filters: {
        ...book.filters,
        doesMatch:
          libraryUtils.bookMatchesPrimaryFilter(book, primary) &&
          libraryUtils.bookMatchesSecondaryFilter(book, secondary)
      }
    }
  });
}

function bookShouldShow(superTopic: string): (book: Book) => boolean {
  return (book: Book): boolean =>
    !!(book.filters.doesMatch && book.filters.supertopic.includes(superTopic));
}
