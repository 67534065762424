import styled from 'styled-components/macro';
import hexToRgba from 'hex-to-rgba';
import {colors, dimensions} from '@bettermarks/bm-ui-components/';

const ModuleImage = styled.div`
  img {
    max-width: 100%;
    cursor: pointer;
  }

  flex: 0 0 100px;
  border-radius: 50%;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background: ${p => p.color && hexToRgba(p.color, 0.2)};
`;

const ImageSafeBGColor = styled.div`
  background: ${colors.cWhite};
  width: 100px;
  height: 100px;
  z-index: 10;
`;

const DetailHeader = styled.header`
  display: flex;
  padding: ${dimensions.spaceXl} ${dimensions.spaceM};
  min-height: 70px;
  background: linear-gradient(
    -90deg,
    ${p => (p.color ? hexToRgba(p.color, 0.2) : colors.cWhite)} 0%,
    ${p => (p.color ? hexToRgba(p.color, 0) : colors.cWhite)} 100%
  );

  ${ImageSafeBGColor} {
    border-radius: 50%;
  }
`;

const DetailContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${dimensions.spaceXl} ${dimensions.spaceM};
  position: relative;
  z-index: 0;
`;

const ModuleDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 ${dimensions.spaceM};
`;

const DetailDetails = styled(ModuleDetails)`
  justify-content: center;
`;

const SubContent = styled.section`
  overflow-y: auto;
`;

const DetailHeadline = styled.h6`
  font-size: ${dimensions.fontSizeM};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceS};
`;

const List = styled.ul`
  line-height: ${dimensions.lineHeightL};
`;
const Item = styled.li``;

const DefinitionList = styled.dl`
  margin: 0;
  padding: 0;
  span + span {
    margin: ${dimensions.spaceL} 0 0;
    display: flex;
    flex-direction: column;
  }
`;

const Term = styled.dt`
  font-size: ${dimensions.fontSizeS};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceXs};
  padding: 0;
`;
const Desc = styled.dd`
  margin: 0;
  padding: 0;
  overflow-x: scroll;
`;

const DetailFooter = styled.footer`
  padding: ${dimensions.spaceM};
  display: flex;
  justify-content: space-between;
`;

const ModuleTitle = styled.h3`
  font-size: ${dimensions.fontSizeM};
  font-weight: normal;
  line-height: ${dimensions.lineHeightM2};
  color: ${colors.cGray800};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const ModuleSuperTopic = styled.h6`
  font-size: ${dimensions.fontSizeXs};
  line-height: ${dimensions.lineHeightXs};
  color: ${colors.cGray700};
  font-weight: normal;
  margin: 0;
  padding: 0;
`;
export const ModalDetails = {
  DetailHeader,
  ImageSafeBGColor,
  DetailDetails,
  DetailContent,
  DetailHeadline,
  DetailFooter,
  ModuleDetails,
  ModuleImage,
  ModuleSuperTopic,
  Term,
  Desc,
  ModuleTitle,
  SubContent,
  List,
  Item,
  DefinitionList
};
