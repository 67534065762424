import {ReactElement} from 'react';
import {
  ButtonLayout,
  colors,
  dimensions,
  Footer,
  ModuleKnowledgeIntro,
  StyledFC,
  TileImageItem,
  TileTextItem,
  ModuleHeader
} from '@bettermarks/bm-ui-components';
import styled, {css} from 'styled-components/macro';
import {ModalDetails} from './modal.sub-components';
import {DetailHeader} from '../DetailTile';
import {AnimatePresence, motion} from 'framer-motion';

const {DetailContent, SubContent, DefinitionList, Term, Desc} = ModalDetails;

export interface ModalProps {
  className?: string;
  color: string;
  modalOpen: boolean;
  modalTitle?: string;
  title: string;
  subtitle?: string;
  image?: string;
  closeModal: () => void;
  examples?: TileImageItem[];
  preknowledge?: TileTextItem[];
  objectives?: TileTextItem[];
  footer?: ReactElement;
}

const _PreviewModal: StyledFC<ModalProps> = ({
  className,
  color,
  modalTitle,
  title,
  image,
  examples,
  objectives,
  preknowledge,
  modalOpen,
  closeModal,
  footer
}) => {
  return modalOpen ? (
    <div className={className}>
      <DetailHeader modalTitle={modalTitle} onClose={closeModal} />

      <DetailContent>
        <ModuleHeader
          image={image}
          color={color}
          title={title}
          context='modal'
        />
        <ModuleKnowledgeIntro
          objectives={objectives}
          preknowledge={preknowledge}
          context='modal'
        />
        {examples && (
          <SubContent>
            <DefinitionList>
              {examples.map((example: any, i: number) => (
                <span key={i}>
                  <Term>Aufgabe {i + 1}</Term>
                  <Desc>
                    <img
                      draggable='false'
                      src={example.image}
                      alt={`Aufgabe-${i + 1}`}
                    />
                  </Desc>
                </span>
              ))}
            </DefinitionList>
          </SubContent>
        )}
      </DetailContent>
      {footer && <Footer buttonLayout={ButtonLayout.row}>{footer}</Footer>}
    </div>
  ) : (
    <></>
  );
};

export const PreviewModal = styled(_PreviewModal)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: ${colors.cWhite};
  transition: all 0.5s ease-in-out;
  position: fixed;

  ${SubContent} {
    margin-top: 15px;
  }

  ${Footer} {
    padding: ${dimensions.spaceS};
    border-top: 1px solid ${colors.cGray300};
  }
  @media (max-height: 600px) {</div>
    max-height: 90vh;
  }

  @media (min-height: 601px) {
    max-height: 560px;
  }

  @media (min-width: 768px) {
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 767px) {
    min-width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${p =>
    p.modalOpen
      ? css`
          opacity: 1;
          visibility: visible;
          z-index: 10;
        `
      : css`
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
        `};
`;
