import {
  AngleRightIcon,
  colors,
  dimensions,
  HidePasswordIcon,
  Knob,
  KnobType
} from '@bettermarks/bm-ui-components';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import {
  Layout,
  LayoutComponentService
} from '../../components/LayoutComponents';
import {Legend} from '../../components/Legend';
import {SearchBar} from '../../components/SearchBar';
import {Headline, Preamble} from '../Library/librarySelectionSubComponents';
import {PlanningListItem} from './types';
import {usePlanning} from '../../services/planning.service';
import {PlanningRowItem} from './PlanningRowItem';
import {DragEndEvent} from '@dnd-kit/core/dist/types';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {Overlay, useOverlay} from '../../components/Overlay';
import {useLocation, useNavigate} from 'react-router-dom';
import {useRoutes} from '../../services/route.service';
import {StorageService} from '../../services/pt-storage.service';
import {Modal, ModalProps} from '../../components/modal/Modal';
import {useLibrary} from '../../services/library.service';
import {CaretIcon, Orientation} from '../../components/Icons';
import {PreviewModal} from '../../components/modal/PreviewModal';

const RowList = styled.ul`
  display: grid;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  min-width: 250px;
  grid-gap: 0;
  padding: 0;
  margin: ${dimensions.spaceXl} ${dimensions.spaceM} 90px;
  border-radius: 5px;
  min-height: 200px;
  transition: background-color 350ms ease;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
`;
interface PlanningState {
  removeFromPlanning: string;
}

export const PlanningPage: React.FC = () => {
  const PlanningService = usePlanning();
  const OverlayService = useOverlay();
  const navigate = useNavigate();
  const RouteService = useRoutes();
  const LibraryService = useLibrary();
  const location = useLocation();

  const [planningListItems, setPlanningListItems] = useState<
    PlanningListItem[]
  >(PlanningService.planningList());
  const [activeId, setActiveId] = useState<string | null>(null);
  const [legend, setLegend] = useState<string>('');
  const [removeModuleFromPlanning, setRemoveModule] = useState<string>();

  const [modalConfig, setModalConfig] = useState<
    Partial<
      Omit<ModalProps, 'closeModal'> & {
        currentItem: {id: string; libraryId: string};
      }
    >
  >({title: '', modalOpen: false, examples: [], color: '#666'});

  const closeModal = () => {
    setModalConfig({modalOpen: false});
    OverlayService.deactivate();
  };

  const openPreviewModal = (
    id: string,
    libraryId: string,
    title: string,
    color: string,
    image: string
  ): void => {
    OverlayService.fadeIn(() => closeModal());
    const {examples, objectives, preknowledge} =
      LibraryService.chapterIntro(id);

    setModalConfig({
      title,
      color,
      examples,
      objectives,
      preknowledge,
      image,
      modalOpen: true,
      currentItem: {id, libraryId}
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const changeVisibility = (id: string, visible: boolean) =>
    setPlanningListItems(PlanningService.changeVisibilityState(id, visible));
  const remove = (id: string) => {
    const pl = PlanningService.remove(id);
    setTimeout(() => setPlanningListItems(pl), 500);
  };

  const openModule = (id: string, libId: string) =>
    navigate(RouteService.moduleRoute(libId, id, true));

  useEffect(() => {
    const subscr = PlanningService.plState$.subscribe(plState => {
      setLegend(
        `${plState.visible} von ${plState.total} Themen sind für Ihre Schüler sichtbar`
      );
    });

    RouteService.setContentRoute(
      StorageService.load('contentLinkOnPlanningPage', false) ||
        RouteService.librarySelection(StorageService.getVersion())
    );
    LayoutComponentService.header = [<SearchBar key={'planning-search'} />];
    PlanningService.markAsRead();

    if (
      location.state &&
      (location.state as PlanningState).removeFromPlanning
    ) {
      const removeModuleId = (location.state as PlanningState)
        .removeFromPlanning;
      setRemoveModule(removeModuleId);
      setTimeout(() => remove(removeModuleId), 700);
    }

    return () => subscr.unsubscribe();
  }, []);

  return (
    <Layout>
      <Preamble column>
        <Headline>Geplante Themen in “Klasse 5a”</Headline>
        {(planningListItems.length && (
          <Legend
            icon={<HidePasswordIcon fill={colors.cGray700} />}
            label={legend}
          />
        )) ||
          'Sie haben noch keine Module zur Planung hinzugefügt.'}
      </Preamble>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({active}) => {
          setActiveId(active.id);
        }}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <RowList>
          <SortableContext
            items={planningListItems}
            strategy={verticalListSortingStrategy}
          >
            {planningListItems.map(item => (
              <PlanningRowItem
                key={item.id}
                id={item.id}
                isDragging={activeId === item.id}
                moduleName={item.name}
                supertopic={
                  LibraryService.getLibraryName(item.libraryId) +
                  ' > ' +
                  item.superTopic
                }
                icon={'/topic-icons/' + item.bookIcon}
                color={item.color}
                visible={item.visible}
                removeSelf={removeModuleFromPlanning === item.id}
                onRemove={() => remove(item.id)}
                onPreview={() =>
                  openPreviewModal(
                    item.id,
                    item.libraryId,
                    item.name,
                    item.color,
                    '/topic-icons/' + item.bookIcon
                  )
                }
                onVisibilityChange={visible =>
                  changeVisibility(item.id, visible)
                }
                onOpenModule={() => openModule(item.id, item.libraryId)}
              />
            ))}
          </SortableContext>
        </RowList>
      </DndContext>
      <PreviewModal
        image={modalConfig.image}
        color={modalConfig.color || '#666'}
        modalTitle='Vorschau'
        title={modalConfig.title || ''}
        examples={modalConfig.examples}
        modalOpen={!!modalConfig.modalOpen}
        objectives={modalConfig.objectives}
        preknowledge={modalConfig.preknowledge}
        closeModal={closeModal}
        footer={
          <Knob
            label='Öffnen'
            type={KnobType.button}
            iconAppended={
              <CaretIcon fill={colors.cWhite} orientation={Orientation.right} />
            }
            onClick={() =>
              openModule(
                modalConfig.currentItem!.id,
                modalConfig.currentItem!.libraryId
              )
            }
          />
        }
      />
      <Overlay />
    </Layout>
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    setActiveId(null);
    if (over && active.id !== over?.id) {
      setPlanningListItems(PlanningService.moveItemAboveX(active.id, over.id));
    }
  }
};
