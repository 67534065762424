import styled from 'styled-components/macro';
import {dimensions, colors, StyledFC} from '@bettermarks/bm-ui-components';
import {Link as _RouterLink} from 'react-router-dom';
import {CaretIcon, Orientation} from './Icons';

export interface LibrarySelectorProps {
  label: Readonly<string>;
  routerLink: string;
}

const LibraryTitle = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const LibraryIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 ${dimensions.spaceM};
`;

const Library = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedLib = styled.span`
  color: ${colors.cBlue700};
`;

const RouterLink = styled(_RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  padding: 10.5px 12px;
  gap: 8px;
  border-radius: ${dimensions.spaceXxs};
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: ${colors.cGray100};
  }
`;

const _LibrarySelector: StyledFC<LibrarySelectorProps> = ({
  label,
  routerLink,
  className
}) => (
  <RouterLink className={className} to={routerLink}>
    <CaretIcon orientation={Orientation.left} />
    {label}
  </RouterLink>
);

const LibrarySelector = styled(_LibrarySelector)`
  font-size: ${dimensions.fontSizeM};
  display: inline-flex;
  color: ${colors.cBlue700};
  padding: 8.5px ${dimensions.spaceM};
`;

export default LibrarySelector;
