import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export enum Decoration {
  plus = 'plus',
  minus = 'minus',
  checkmark = 'checkmark'
}

interface CalendarProps {
  fill?: string;
  width?: number;
  height?: number;
  decoration?: Decoration;
}

const CalendarDecoration: React.FC<CalendarProps> = ({decoration, fill}) => {
  switch (decoration) {
    case Decoration.plus:
      return (
        <path
          id='plus'
          d='M12.8963 12H14.1327V16.8931H19.1066V18.1188H14.1327V23H12.8963V18.1188H8V16.8931H12.8963V12Z'
          fill={fill || colors.cWhite}
        />
      );
    case Decoration.minus:
      return (
        <path
          id='minus'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.4853 17.5L9.24264 13.2574L9.94975 12.5503L14.1924 16.7929L18.435 12.5503L19.1421 13.2574L14.8995 17.5L19.1421 21.7426L18.435 22.4497L14.1924 18.2071L9.94975 22.4497L9.24264 21.7426L13.4853 17.5Z'
          fill={fill || colors.cWhite}
        />
      );
    case Decoration.checkmark:
      return (
        <path
          id='checkmark'
          d='M11.7736 22.8076L20.8551 13.7261L19.9366 12.8076L11.7736 20.9706L7.9185 17.1155L7 18.034L11.7736 22.8076Z'
          fill={fill || colors.cWhite}
        />
      );

    default:
      return <></>;
  }
};

export const CalendarIcon: React.FC<CalendarProps> = ({
  decoration,
  width,
  height,
  fill
}) => {
  return (
    <svg
      width={width || 28}
      height={height || 28}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <CalendarDecoration decoration={decoration} fill={fill} />
      <path
        id='calendar'
        d='M19.2288 2.00375V0H17.1987V2.00375H10.1112V0H8.08125V2.00375H0V28H27.3013V2.00375H19.2288ZM26.2075 26.9062H1.11125V7.98H26.2075V26.9062ZM26.2075 7.02625H1.11125V3.10625H8.09875V5.01375H10.1112V3.10625H17.1987V5.01375H19.2288V3.10625H26.2075V7.02625Z'
        fill={fill || '#1188EE'}
      />
    </svg>
  );
};
