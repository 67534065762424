import React, {useEffect} from 'react';
import {LibrarySelection} from './LibrarySelection';
import {LibraryGroup} from '../../types';
import {useLocation} from 'react-router-dom';
import {PtVersion, useStorage} from '../../services/pt-storage.service';
import {SearchBar} from '../../components/SearchBar';
import {Preamble} from './librarySelectionSubComponents';
import {
  Layout,
  LayoutComponentService
} from '../../components/LayoutComponents';
import {useRoutes} from '../../services/route.service';

export const LibrarySelectionPage: React.FC<{libraryGroups: LibraryGroup[]}> =
  ({libraryGroups}) => {
    const location = useLocation();
    const StorageService = useStorage();
    const RouteService = useRoutes();

    useEffect(() => {
      StorageService.setVersion(
        (location.pathname.endsWith('v2') && PtVersion.LibraryGroupsPerClass) ||
          PtVersion.LibraryGroupsPerSubject
      );

      LayoutComponentService.header = [
        <SearchBar isSelectionPage={true} key={'search-bar'} />
      ];
      return () => RouteService.setContentRoute(location.pathname);
    }, []);
    const activeLibrary =
      StorageService.load<string>(StorageService.getVersion() + '__library') ||
      undefined;

    return (
      <Layout paddingBottom>
        <Preamble>
          <p>Wählen Sie einen Bereich aus.</p>
        </Preamble>
        <LibrarySelection
          libraryGroups={libraryGroups}
          lastLibrary={activeLibrary}
        />
      </Layout>
    );
  };
