import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRoutes} from '../../services/route.service';
import {
  CalendarIcon,
  CaretLeftIcon,
  CaretRightIcon,
  colors,
  Decoration,
  Knob,
  KnobType,
  ToolBar,
  ToolBarType
} from '@bettermarks/bm-ui-components';
import {ModuleContext} from '../../types';
import styled from 'styled-components';
import {StyledToolBar} from '../../components/LayoutComponents';

export const ModuleBar: React.FC<{
  libraryId: string;
  isPlanned: boolean;
  context: ModuleContext;
  onAddToPlanning: () => void;
  onRemoveFromPlanning: () => void;
}> = ({
  libraryId,
  isPlanned,
  onAddToPlanning,
  context,
  onRemoveFromPlanning
}) => {
  const navigate = useNavigate();
  const RouteService = useRoutes();
  const goToLibrary = () => navigate(RouteService.libraryRoute(libraryId));
  const goToPlanning = () => navigate('/planning');

  const inLibCtxt = context === ModuleContext.Library;
  return (
    <StyledToolBar
      toolBarType={ToolBarType.Secondary}
      leftArea={
        <Knob
          label={(inLibCtxt && 'Zurück') || 'Zurück zur Planung'}
          type={KnobType.link}
          iconPrepended={
            <CaretLeftIcon width={10} height={14} fill={colors.cBlue700} />
          }
          onClick={(inLibCtxt && goToLibrary) || goToPlanning}
        />
      }
      rightArea={
        !isPlanned && (
          <Knob
            label='Zur Planung hinzufügen'
            iconPrepended={<CalendarIcon decoration={Decoration.plus} />}
            onClick={onAddToPlanning}
            type={KnobType.link}
          />
        )
      }
    />
  );
};
