import styled from 'styled-components';
import {StyledFC, dimensions, colors} from '@bettermarks/bm-ui-components';
import {ActivityType, LobProps, ContentTypeProps, LobType} from './types';
import {CheckBox} from '../Checkbox';
import {
  FileListIcon,
  KemListIcon,
  TextListIcon,
  ExerciseListIcon,
  FemListIcon,
  CaretIcon,
  Orientation
} from '../Icons';

function switchIcon(activityType: ActivityType, useSmallIcons: any) {
  switch (activityType) {
    case ActivityType.ExerciseList:
      return (
        <ExerciseListIcon
          width={useSmallIcons ? 16 : 25}
          height={useSmallIcons ? 20 : 30}
        />
      );
    case ActivityType.FemList:
      return (
        <FemListIcon
          width={useSmallIcons ? 16 : 25}
          height={useSmallIcons ? 20 : 30}
          fill={useSmallIcons && colors.cGray700}
        />
      );
    case ActivityType.FileList:
      return (
        <FileListIcon
          width={useSmallIcons ? 16 : 25}
          height={useSmallIcons ? 20 : 30}
          fill={useSmallIcons && colors.cGray700}
        />
      );
    case ActivityType.KemList:
      return (
        <KemListIcon
          width={useSmallIcons ? 16 : 25}
          height={useSmallIcons ? 20 : 30}
          fill={useSmallIcons && colors.cGray700}
        />
      );
    case ActivityType.TextList:
      return (
        <TextListIcon
          width={useSmallIcons ? 20 : 30}
          height={useSmallIcons ? 20 : 30}
          fill={useSmallIcons && colors.cGray700}
        />
      );
    default:
      return (
        <ExerciseListIcon
          width={useSmallIcons ? 17 : 25}
          height={useSmallIcons ? 21 : 30}
        />
      );
  }
}

function switchLabel(activityType: ActivityType) {
  switch (activityType) {
    case ActivityType.ExerciseList:
      return <Label>Interaktive Übung</Label>;
    case ActivityType.FemList:
      return <Label>Begriffe</Label>;
    case ActivityType.FileList:
      return <Label>Download</Label>;
    case ActivityType.KemList:
      return <Label>Beispiele</Label>;
    case ActivityType.TextList:
      return <Label>Erkunden</Label>;
    default:
      return <Label>Interaktive Übung</Label>;
  }
}

const Label = styled.span`
  margin-left: ${dimensions.spaceXs};
`;
const _ContentType: StyledFC<ContentTypeProps> = ({
  className,
  activityType,
  label,
  useSmallIcons
}) => {
  return (
    <div className={className}>
      {switchIcon(activityType, useSmallIcons)}{' '}
      {label && switchLabel(activityType)}
    </div>
  );
};

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled(Row)`
  flex-direction: column;
`;

export const ContentType = styled(_ContentType)<
  Pick<ContentTypeProps, 'label' | 'useSmallIcons'>
>`
  flex: 0 0 ${p => (p.useSmallIcons ? 'auto' : '32px')};
  display: flex;
  flex-direction: ${p => (p.label ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
`;

const Details = styled(Column)`
  flex: 1 1 auto;
  justify-content: center;
  padding: 0 ${dimensions.spaceM};
`;

const ActionButton = styled.button<{active?: boolean}>`
  border: 0;
  cursor: pointer;
  outline: 0;
  background: transparent;
  padding: 0;
`;

const Actions = styled(Row)`
  flex: 0 0 auto;
  ${ActionButton} + ${ActionButton} {
    margin: 0 0 0 ${dimensions.spaceM};
  }
`;

const Title = styled.h6`
  font-weight: 400;
  font-size: ${dimensions.fontSizeM};
  line-height: ${dimensions.lineHeightM2};
  color: ${colors.cGray800};
  margin: 0;
`;

const MetaInfo = styled.span`
  font-size: ${dimensions.fontSizeS};
  line-height: ${dimensions.lineHeightM};
  color: ${colors.cGray700};
  display: flex;
  align-items: center;
  gap: ${dimensions.spaceS};
`;

const Caption = styled.span`
  /* display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center; */
  margin-right: ${dimensions.spaceXxs};
`;

const Wrapper = styled.div<{
  checked?: boolean;
  active?: boolean;
  activeIndex?: string;
}>`
  display: flex;
  flex: 1;

  transition: background 0.25s ease-in-out;
`;

const ClickArea = styled.div<{active?: boolean}>`
  transition: background 0.25s ease-in-out;
  background: ${p => (p.active ? colors.cBlue200 : 'transparent')};
  display: flex;
  padding: ${dimensions.spaceS};
  flex: 1;
  cursor: pointer;
  min-height: 60px;
  border-bottom: 1px solid ${colors.cGray400};
`;

const _Lob: StyledFC<LobProps> = ({
  className,
  caption,
  title,
  activityType,
  actionOpenDetails,
  active,
  activeIndex,
  checked,
  changeHandler,
  meta,
  contentTasks,
  fems
}) => {
  return (
    <div className={className}>
      <Wrapper activeIndex={activeIndex}>
        <CheckBox checked={checked} onClick={changeHandler} />
        <ClickArea active={active} onClick={actionOpenDetails}>
          <ContentType activityType={activityType} />
          <Details>
            <Title>
              {caption && <Caption>{caption.substring(2)}</Caption>}
              {title}
            </Title>
            {meta && <MetaInfo>{meta}</MetaInfo>}
            {contentTasks.length > 1 && (
              <MetaInfo>
                Vorbereitende Inhalte:
                {contentTasks.slice(1).map(
                  (
                    task: {
                      content_type: ActivityType;
                    },
                    id: number
                  ) => (
                    <ContentType
                      key={id}
                      activityType={task.content_type}
                      label
                      useSmallIcons
                    />
                  )
                )}
              </MetaInfo>
            )}
          </Details>
          {console.log(fems)}
          <Actions>
            <ActionButton>
              <CaretIcon orientation={Orientation.right} />
            </ActionButton>
          </Actions>
        </ClickArea>
      </Wrapper>
    </div>
  );
};

export const Lob = styled(_Lob)`
  display: flex;
  user-select: none;

  ${Title} + ${MetaInfo} {
    margin: ${dimensions.spaceXxs} 0 0;
  }
  ${CheckBox} {
    margin-right: 12px;
  }
`;
