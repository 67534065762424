import React from 'react';

export const AddIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='13'
    height='14'
    viewBox='0 0 13 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 7.5V13.5H7V7.5H13V6.5H7V0.5H6V6.5H0V7.5H6Z'
      fill='#1188EE'
    />
  </svg>
);
