import React from 'react';
import {AnyStyledComponent} from 'styled-components';

export enum ActivityType {
  ExerciseList = 'ExerciseList',
  FileList = 'FileList',
  TextList = 'TextList',
  FemList = 'FemList',
  KemList = 'KemList'
}

export interface ContentTypeProps {
  activityType: ActivityType;
  useSmallIcons?: boolean;
  label?: boolean;
  onClick?: () => void;
  active?: boolean;
  checked?: boolean;
}

export enum LobType {
  Single = 'Single',
  Multi = 'Multi'
}

export interface LobProps extends ContentTypeProps {
  caption: string | undefined;
  title: string;
  actionOpenDetails?: () => void;
  actionCheck?: () => void;
  lobType?: LobType;
  activeIndex?: string;
  lobCount?: number;
  meta?: string;
  changeHandler?: () => void;
  contentTasks?: any;
  fems?: any;
}
