import {
  ButtonLayout,
  colors,
  dimensions,
  Footer,
  StyledFC,
  Knob,
  KnobSize,
  KnobType,
  AddIcon,
  CaretLeftIcon
} from '@bettermarks/bm-ui-components';
import styled, {css} from 'styled-components/macro';
import {ModalDetails} from './modal.sub-components';
import {DetailHeader} from './DetailHeader';
import {AnimatePresence, motion} from 'framer-motion';
import {forwardRef, Key, useEffect, useState} from 'react';
import DatePicker, {ReactDatePicker, registerLocale} from 'react-datepicker';
// import setHours from 'date-fns/setHours';
// import setMinutes from 'date-fns/setMinutes';
import {setHours, setMinutes} from 'date-fns';
import de from 'date-fns/locale/de';
registerLocale('de', de);
import '../../themes/datepicker/datepicker.scss';
import classes from '../../data/classes/classes.json';
import {CloseIcon} from '../Icons/Close';

import {CheckBox} from '../Checkbox';

const {DetailContent, SubContent, DefinitionList, Term, Desc} = ModalDetails;

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  solid,
  regular,
  brands
} from '@fortawesome/fontawesome-svg-core/import.macro';

export interface ModalProps {
  className?: string;
  modalOpen: boolean;
  modalTitle?: any;
  title: string;
  subtitle?: string;
  closeModal: () => void;
  asDetailTooltip?: boolean;
}

export interface AllocateModalProps {
  className?: string;
  modalOpen: boolean;
  modalTitle?: any;
  closeModal: () => void;
  asDetailTooltip?: boolean;
  lobList: any[];
  createAssignment: () => void;
  onRemoveMiniLob: () => void;
}

const StyledFooter = styled(Footer)`
  justify-content: space-between;
`;

/* Tabs */
interface TabNavItemProps {
  id: string;
  title: any;
  activeTab: any;
  setActiveTab: any;
  icon?: any;
}

const Button = styled.button<{active?: boolean}>`
  outline: 0;
  padding: 0 20px 0 12px;
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  border-radius: ${dimensions.spaceXxs};
  font-size: ${dimensions.fontSizeM};
  line-height: 100%;
  cursor: pointer;
  border: 1px solid;
  ${p =>
    p.active
      ? css`
          background-color: ${colors.cBlue200};
          border-color: ${colors.cBlue700};
          color: ${colors.cBlue700};
        `
      : css`
          background-color: ${colors.cWhite};
          border-color: ${colors.cGray700};
          color: ${colors.cGray700};
        `};
`;

const IconBox = styled.span`
  margin-right: 12px;
`;

const _TabNavItem: StyledFC<TabNavItemProps> = ({
  className,
  id,
  icon,
  title,
  activeTab,
  setActiveTab
}) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li className={className}>
      <Button onClick={handleClick} active={activeTab === id}>
        {icon && <IconBox> {icon}</IconBox>}
        {title}
      </Button>
    </li>
  );
};

const TabNavItem = styled(_TabNavItem)`
  display: inline-flex;
`;

const TabPanel: StyledFC<{id: string; activeTab: any; children: any}> = ({
  id,
  activeTab,
  children,
  className
}) => {
  return activeTab === id ? (
    <StyledTabPanel
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{ease: 'easeInOut', duration: 0.25}}
      className={className}
    >
      {children}
    </StyledTabPanel>
  ) : null;
};

const StyledTabPanel = styled(motion.div)`
  display: flex;
  flex: 1;
`;

const TabNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: ${dimensions.spaceXl};
  margin: 0;
  gap: ${dimensions.spaceS};
}
`;

const TabPanels = styled.div`
  display: flex;
  min-height: 364px;
  height: 405px;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;
/* Tabs */
const Calendar = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  width: 100%;
  & > div {
    display: flex;
    flex: 1;
  }
`;
const StyledDetailHeader = styled(DetailHeader)`
  justify-content: center;
`;

const RowContainer = styled.div<{padding?: boolean; scroll?: boolean}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${p => (p.padding ? '24px' : 0)};
  border-top: 1px solid ${colors.cGray400};
  overflow-y: ${p => (p.scroll ? 'auto' : 'hidden')};
`;

interface MiniLobProps {
  title: any;
  onRemoveMiniLob: any;
}

const _MiniLobItem: StyledFC<MiniLobProps> = ({
  className,
  title,
  onRemoveMiniLob
}) => {
  return (
    <li className={className}>
      <button onClick={onRemoveMiniLob}>
        <CloseIcon fill={colors.cWhite} />
      </button>
      <h6>{title}</h6>
    </li>
  );
};

const MiniLobItem = styled(_MiniLobItem)`
  display: flex;
  padding: 12px;
  min-height: 54px;
  align-items: center;
  button {
    flex: 0 0 24px;
    border-radius: 50%;
    background-color: ${colors.cBlue700};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    oultine: 0;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 16px;
    padding: 0;
    flex: 1 1 auto;
  }
`;

const ButtonContainer = styled.div`
  padding: 8px 12px;
  min-height: 54px;
  position: sticky;
  width: 100%;
  bottom: 0;
  ${Knob} {
    margin-right: auto;
  }
`;

const _MiniLob: StyledFC<{lobs: any; onClose: any; onRemoveMiniLob: any}> = ({
  className,
  lobs,
  onClose,
  onRemoveMiniLob
}) => {
  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);

  return (
    <>
      <ul className={className}>
        {lobs.map((lob: any, idx: Key | null | undefined) => (
          <MiniLobItem
            onRemoveMiniLob={onRemoveMiniLob}
            key={idx}
            title={lob}
          />
        ))}
      </ul>
      <ButtonContainer>
        <Knob
          label='Weitere Lernziele hinzufügen'
          iconPrepended={<AddIcon />}
          type={KnobType.link}
          size={KnobSize.large}
          onClick={onClose}
        />
      </ButtonContainer>
    </>
  );
};

const MiniLob = styled(_MiniLob)`
  display: flex;

  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  li:nth-child(even) {
    background-color: #fafafa;
  }
`;

interface ClassListProps {
  id: string;
  name: string;
  activeClass: any;
  setActiveClass: any;
}

const Radio = styled.span<{checked?: boolean}>`
  width: 16px;
  height: 16px;
  display: flex;
  border: 2px solid ${p => (p.checked ? 'transparent' : colors.cBlue700)};
  border-radius: 50%;
  background-color: ${p => (p.checked ? colors.cBlue700 : colors.cWhite)};
`;

const Name = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div<{active?: boolean}>`
  transition: background-color 0.25s ease-in-out;
  background-color: ${p => (p.active ? colors.cBlue200 : colors.cWhite)};
  display: flex;
  flex: 1;
  padding: 14px 12px;
  align-items: center;
`;

const RadioInput = styled.span<{active: any}>`
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  border: 2px solid ${p => (p.active ? 'transparent' : colors.cBlue700)};
  background-color: ${p => (p.active ? colors.cBlue700 : colors.cWhite)};
`;

const _ClassListItem: StyledFC<ClassListProps> = ({
  className,
  id,
  name,
  activeClass,
  setActiveClass
}) => {
  const handleClick = () => {
    setActiveClass(id);
  };
  return (
    <li className={className} onClick={handleClick}>
      <Wrapper active={activeClass === id}>
        <RadioInput active={activeClass === id} />
        <Name>{name}</Name>
      </Wrapper>
    </li>
  );
};

const ClassListItem = styled(_ClassListItem)`
  display: flex;
  border-bottom: 1px solid ${colors.cGray400};
  cursor: pointer;
  ${RadioInput} {
    margin-right: 12px;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  /* max-height: 350px; */
    overflow-y: auto;
    overflow-x: hidden;
}
`;
const ClickArea = styled.div<{active: boolean}>`
  background: ${p => (p.active ? colors.cBlue200 : colors.cWhite)};
  display: flex;
  flex: 1;
  width: 100%;
`;

const _StudentRow: StyledFC<{
  firstName: string;
  lastName: string;
  checked: boolean;
  onCheck: any;
}> = ({className, firstName, lastName, onCheck, checked}) => {
  return (
    <li className={className}>
      <ClickArea active={checked} onClick={onCheck}>
        <CheckBox small checked={checked} padding='12px' />
        <Name>
          {firstName} {lastName}
        </Name>
      </ClickArea>
    </li>
  );
};

const StudentRow = styled(_StudentRow)`
  border-bottom: 1px solid ${colors.cGray400};
  cursor: pointer;
`;

const _AllocationModal: StyledFC<AllocateModalProps> = ({
  className,
  modalTitle,
  modalOpen,
  lobList,
  closeModal,
  createAssignment,
  onRemoveMiniLob
}) => {
  const [activeTab, setActiveTab] = useState('tab1');

  const [activeClass, setActiveClass] = useState('Klasse 5a');

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minH = setHours(setMinutes(today, 0), 0);
  const maxH = setHours(setMinutes(today, 59), 23);
  //const nextWeek = today.getDate() + 7;
  const [startDate, setStartDate] = useState(minH);
  const [endDate, setEndDate] = useState(maxH);

  const [value, setValue] = useState(false);

  function handleCheckStudent(id: string) {
    if (checked.indexOf(id) > -1) {
      isChecked(prev => prev.filter(i => i !== id));
    } else {
      isChecked(prev => [...prev, id]);
    }
  }

  const studentList = classes[1].students;

  const initialList = studentList.map(student => student.firstName);

  const [studentId, setStudentId] = useState<{selections: string[]}>({
    selections: initialList
  });

  const studentSelection = studentId.selections.length;

  const allChecked = studentSelection === studentList.length;

  function handleCheckboxChange(key: string) {
    let sel = studentId.selections;
    let find = sel.indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }

    setStudentId({
      selections: sel
    });
  }

  const [checked, isChecked] = useState<string[]>([]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          initial={{opacity: 0, x: 480}}
          animate={{opacity: 1, x: 0}}
          transition={{ease: 'easeOut', duration: 0.1}}
          className={className}
        >
          <StyledDetailHeader
            contentType={false}
            modalTitle={modalTitle}
            onClose={closeModal}
            closeFixed={false}
          />

          <RowContainer scroll>
            <Tabs>
              <TabNav>
                <TabNavItem
                  id='tab1'
                  icon={<FontAwesomeIcon icon={solid('calendar-days')} />}
                  title={
                    `${startDate === today ? 'Heute ' : ''}` +
                    `${startDate.toLocaleDateString('de-De', {
                      month: 'numeric',
                      day: 'numeric',
                      weekday: 'short'
                    })}` +
                    ` – ` +
                    `${endDate.toLocaleDateString('de-DE', {
                      month: 'numeric',
                      day: 'numeric',
                      weekday: 'short'
                    })}`
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  id='tab2'
                  icon={<FontAwesomeIcon icon={solid('address-book')} />}
                  title={activeClass}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  id='tab3'
                  title={`${
                    !allChecked
                      ? studentSelection > 1
                        ? studentSelection + ' Personen'
                        : studentSelection + ' Person'
                      : 'Alle Personen'
                  } `}
                  icon={<FontAwesomeIcon icon={solid('users')} />}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  id='tab4'
                  icon={<FontAwesomeIcon icon={solid('circle-exclamation')} />}
                  title={`${lobList.length} ${
                    lobList.length > 1 ? 'Lernziele' : 'Lernziel'
                  }`}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </TabNav>
              <TabPanels>
                <TabPanel id='tab1' activeTab={activeTab}>
                  <RowContainer padding>
                    <Calendar>
                      <DatePicker
                        locale={de}
                        minDate={today}
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        inline
                        dateFormat='MM/dd/yyyy'
                        showTimeInput
                        isClearable
                        timeInputLabel='von:'
                      />

                      <DatePicker
                        locale={de}
                        minDate={today}
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        inline
                        dateFormat='MM/dd/yyyy h:mm aa'
                        showTimeInput
                        timeInputLabel='bis:'
                      />
                    </Calendar>
                  </RowContainer>
                </TabPanel>
                <TabPanel id='tab2' activeTab={activeTab}>
                  <RowContainer>
                    <List>
                      <ClassListItem
                        id='Klasse 5a'
                        name='Klasse 5a'
                        activeClass={activeClass}
                        setActiveClass={setActiveClass}
                      />
                      <ClassListItem
                        id='Klasse 5b'
                        name='Klasse 5b'
                        activeClass={activeClass}
                        setActiveClass={setActiveClass}
                      />
                      <ClassListItem
                        id='Klasse 6c'
                        name='Klasse 6c'
                        activeClass={activeClass}
                        setActiveClass={setActiveClass}
                      />
                    </List>
                  </RowContainer>
                </TabPanel>
                <TabPanel id='tab3' activeTab={activeTab}>
                  <RowContainer>
                    <List>
                      {studentList.map(student => (
                        <StudentRow
                          key={student.firstName}
                          firstName={student.firstName}
                          lastName={student.lastName}
                          checked={studentId.selections.includes(
                            student.firstName
                          )}
                          onCheck={() =>
                            handleCheckboxChange(student.firstName)
                          }
                        />
                      ))}
                    </List>
                  </RowContainer>
                </TabPanel>
                <TabPanel id='tab4' activeTab={activeTab}>
                  <RowContainer>
                    <MiniLob
                      lobs={lobList}
                      onRemoveMiniLob={onRemoveMiniLob}
                      onClose={closeModal}
                    />
                  </RowContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </RowContainer>
          <StyledFooter buttonLayout={ButtonLayout.row}>
            <Knob
              label='Auswahl anpassen'
              type={KnobType.link}
              iconPrepended={<CaretLeftIcon width={12} height={12} />}
              size={KnobSize.large}
              onClick={closeModal}
            />
            <Knob
              label='Zuweisen'
              type={KnobType.button}
              size={KnobSize.large}
              onClick={createAssignment}
            />
          </StyledFooter>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const CUSTOM_POSITION = css`
  right: 0;
  top: 120px;
  bottom: 56px;
`;

export const AllocationModal = styled(_AllocationModal)<
  Pick<ModalProps, 'asDetailTooltip'>
>`
  display: flex;
  flex-direction: column;
  border-radius: ${p => (p.asDetailTooltip ? 0 : '6px')};
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: ${colors.cWhite};
  transition: all 0.5s ease-in-out;
  position: fixed;
  z-index: 1;

  ${SubContent} {
    margin-top: 15px;
  }

  ${Footer} {
    padding: ${dimensions.spaceS};
    border-top: 1px solid ${colors.cGray300};
    margin: 0;
  }
  @media (max-height: 600px) {
    max-height: 90vh;
  }

  @media (min-width: 768px) {
    width: 640px;
    ${p =>
      p.asDetailTooltip
        ? CUSTOM_POSITION
        : css`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
          `};
  }
  @media (max-width: 767px) {
    min-width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${p =>
    p.modalOpen
      ? css`
          opacity: 1;
          visibility: visible;
          z-index: 10;
        `
      : css`
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
        `};
`;
