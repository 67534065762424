import {Observable} from 'rxjs';
import {
  ModuleDetailProps,
  OptionSwitcherItem,
  TileImageItem
} from '@bettermarks/bm-ui-components';
import {PtVersion} from './services/pt-storage.service';
import {ActivityType} from './components/Lob/types';

export interface Domain {
  name: string;
  color: string;
  banner: string;
  superTopics: SuperTopic[];
}

export interface SuperTopic {
  name: string;
  color: string;
  books: Book[];
}

export interface Book {
  bookType: string;
  contentLocale: string;
  bookIcon: string;
  id: string;
  name: string;
  order: string;
  color?: string;
  colors?: {primary: string; secondary: string; tertiary: string}[];
  filters: {
    classlevel: string[];
    supertopic: string[];
    doesMatch?: boolean;
  };
  search_fields?: string[];
  activation_state?: string;
  version?: string;
  permissions?: {
    [key: string]: string[];
  };
}

export interface Filter {
  label?: string;
  default?: string;
  identifier: string;
  values: FilterItem[];
}

export interface BmProductionFilter {
  parent?: string;
  label?: string;
  default?: string;
  identifier: string;
  values: OptionSwitcherItem[];
}

export interface FilterItem extends OptionSwitcherItem {
  children: string[];
  itemsPerFilterCombination: Map<string, number>;
}

export interface BmProductionLibrary {
  books: Book[];
  filters: BmProductionFilter[];
}

export interface LibraryFilterProps {
  primary: Filter;
  secondary: Filter;
  activeFilters: string;
  filterChange?: (updatedFilter: FilterUpdatedEvent) => void;
}

export interface FilterUpdatedEvent {
  type: keyof Pick<LibraryFilterProps, 'primary' | 'secondary'>;
  updatedItem: FilterItem;
}

export type Library = Domain[];

export interface ColorAndIconMapping {
  domains: {
    name: string;
    banner: string;
    color: string;
  }[];
  superTopics: {
    name: string;
    color: string;
  }[];
  books: {
    id: string;
    bookIcon: string;
    color?: string;
  }[];
}

export interface LibraryServiceApi {
  library$: (version: PtVersion, libId?: string) => Observable<Library>;
  libraryFilter$: () => Observable<LibraryFilterProps>;
  onFilterChanged: (ev: FilterUpdatedEvent) => void;
  emptyFilter: () => Filter;
  chapterIntro: (moduleId: string) => ModuleDetailProps;
  getModule: (moduleId: string) => Module;
  getPreviewExerciseList: (contentListRef: string) => TileImageItem[];
  getLibraryName: (id: string) => string;
  getDomainForModule: (library: Library, module: Module) => Domain | undefined;
}

export interface ModuleChapter {
  id: string;
  intro: string;
  name: string;

  type: 'chapter';
  caption: string;
  intro_path: string;
  chapters: {
    id: string;
    name: string;
    type: 'chapter';
    caption: string;
    pdf_path: string;
    meta: string;
    chapters: {
      type: 'content';
      content_list_ref: string;
      level: string;
      label?: string;
      content_type: ActivityType;
      fems?: any;
    }[];
  }[];
}

export interface Module {
  id: string;
  name: string;
  order: string;
  bookType: 'school';
  bookIcon: string;
  multipleSystems?: boolean;
  publisher?: string;
  version?: string;
  contentLocale?: string;
  activation_state: 'active' | 'inactive';
  filters: {
    classlevel: string[];
    supertopic: string[];
  };
  search_fields: string[];
  bookTest: {
    name: string;
    id: string;
    type: 'bookTest';
    references: {
      type: 'content';
      content_list_ref: string;
      level: string;
      label: string;
      content_type: 'ExerciseList';
    }[];
    chapters: {}[];
  };
  chapters: ModuleChapter[];
}

export interface LibraryGroupItem {
  id: string;
  title: string;
  themeCount: number;
  classRange?: string;
  isActive?: boolean;
  icon?: string;
  enabled?: boolean;
  beta?: boolean;
}

export interface LibraryGroup {
  libraryGroup: string;
  content: LibraryGroupItem[];
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export enum ModuleContext {
  Library,
  Planning
}
