import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {LibraryPage} from './pages/Library/Library.page';
import {LibrarySelectionPage} from './pages/Library/LibrarySelection.page';

import LibraryV1 from './data/libraries/libraries.v1.json';
import LibraryV2 from './data/libraries/libraries.v2.json';
import {ModulePage} from './pages/Module/Module.page';
import {PlanningPage} from './pages/Planning/Planning.page';
import {Layout} from './components/LayoutComponents';
import {RouteService} from './services/route.service';
import {PtVersion} from './services/pt-storage.service';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ModuleContext} from './types';

const libSelectionV1 = RouteService.librarySelection(
  PtVersion.LibraryGroupsPerSubject
);
const libSelectionV2 = RouteService.librarySelection(
  PtVersion.LibraryGroupsPerClass
);
if (window.location.pathname === '/') {
  window.location.replace(
    window.location.hostname.startsWith('tc4-v1')
      ? libSelectionV1
      : libSelectionV2
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route
        path={libSelectionV1}
        element={<LibrarySelectionPage libraryGroups={LibraryV1} />}
      />
      <Route
        path={libSelectionV2}
        element={<LibrarySelectionPage libraryGroups={LibraryV2} />}
      />
      <Route path='/library/:libraryId' element={<LibraryPage />} />
      <Route path='/planning' element={<PlanningPage />} />
      <Route
        path='/library/:libraryId/:moduleId'
        element={<ModulePage context={ModuleContext.Library} />}
      />
      <Route
        path='/planning/:libraryId/:moduleId'
        element={<ModulePage context={ModuleContext.Planning} />}
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
