import styled, {css} from 'styled-components';
import {StyledFC, colors, dimensions} from '@bettermarks/bm-ui-components';

export enum KnobType {
  link = 'link',
  ghost = 'ghost',
  button = 'button',
  iconBtn = 'iconBtn'
}

export enum KnobVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export enum IconBtnStyle {
  border = 'border',
  fill = 'fill'
}

export enum KnobSize {
  tiny = 'tiny',
  small = 'small',
  regular = 'regular',
  medium = 'medium',
  large = 'large'
}

export interface KnobProps {
  label?: string;
  type: KnobType;
  variant?: KnobVariant;
  size?: KnobSize;
  iconPrepended?: any;
  iconAppended?: any;
  width?: number;
  height?: number;
  colorizeIcon?: boolean;
  iconBtnStyle?: IconBtnStyle;
  onClick?: any;
  stretch?: boolean;
  disabled?: boolean;
  customWidth?: number;
  maxHeight?: number;
}

const Label = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Knob: StyledFC<KnobProps> = ({
  className,
  label,
  iconPrepended,
  iconAppended,
  onClick
}) => {
  return (
    <button onClick={onClick} className={className}>
      {iconPrepended && <Icon>{iconPrepended}</Icon>}
      {label && <Label>{label}</Label>}
      {iconAppended && <Icon>{iconAppended}</Icon>}
    </button>
  );
};

export const Knob = styled(_Knob)<Pick<KnobProps, 'stretch' | 'customWidth'>>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: ${dimensions.fontSizeM};
  max-height: ${p => (p.maxHeight ? p.maxHeight + 'px' : null)};
  transition: all 0.25s ease-in-out;
  gap: ${dimensions.spaceXs};
  ${p =>
    p.customWidth &&
    css`
      width: ${p.customWidth + 'px'};
    `};
  ${p =>
    p.stretch &&
    css`
      width: 100%;
    `};
  cursor: pointer;
  &,
  path,
  polygon {
    transition: 0.25s all ease-in-out;
  }

  ${p => {
    switch (p.variant) {
      case KnobVariant.primary:
        return css`
          color: ${colors.cBlue700};
        `;
      case KnobVariant.secondary:
        return css`
          color: ${colors.cGray800};
        `;
      default:
        return css`
          color: ${colors.cBlue700};
        `;
    }
  }}

  ${p =>
    p.type === KnobType.button
      ? css`
          background: ${colors.cBlue700};
          color: ${colors.cWhite};
          padding: 8.5px ${dimensions.spaceS};
          border-radius: ${dimensions.borderRadiusM};
          border: none;
          ${p.colorizeIcon &&
          `path {
            fill: ${colors.cWhite};
          }`}

          &:hover {
            background: ${colors.cBlue800};
          }
        `
      : p.type === KnobType.ghost
      ? css`
          border-radius: ${dimensions.borderRadiusM};

          // padding: 8.5px ${dimensions.spaceS};
          background: transparent;
          border: none;
          &:hover {
            background: ${colors.cGray100};
          }
        `
      : p.type === KnobType.link
      ? css`
          border-radius: ${dimensions.borderRadiusM};
         
          background: transparent;
          border: none;
            path,
            polygon {
              fill: ${colors.cBlue700};
            }
          }
          ${
            p.colorizeIcon &&
            `path {
            fill: ${colors.cBlue700};
          }`
          }
          &:hover {
            color: ${colors.cBlue600};
            path, polygon {
              fill: ${colors.cBlue600}; 
            }
          }
        `
      : p.type === KnobType.iconBtn &&
        css`
          cursor: pointer;
          padding: 0;
          margin: 0;
          ${p.iconBtnStyle === IconBtnStyle.border
            ? ` border: 1px solid ${colors.cBlue700};
                background: transparent;`
            : p.iconBtnStyle === IconBtnStyle.fill &&
              `
              background: ${colors.cWhite};
              border: none;
            `}

          outline: none;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: ${p.width || 28}px;
          height: ${p.height || 28}px;
          flex-shrink: 0;
          &:hover {
            background: ${colors.cBlue200};
            border-color: ${colors.cBlue200};
          }
        `}
  ${p => {
    switch (p.size) {
      case KnobSize.tiny:
        return css`
          font-size: ${dimensions.fontSizeS};
          padding: 6px ${dimensions.spaceS};
        `;
      case KnobSize.small:
        return css`
          font-size: ${dimensions.fontSizeS};
          padding: 7px ${dimensions.spaceS};
        `;
      case KnobSize.regular:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 8.5px ${dimensions.spaceS};
        `;
      case KnobSize.medium:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 10.5px ${dimensions.spaceS};
        `;
      case KnobSize.large:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 12.5px ${dimensions.spaceS};
        `;
      default:
        css`
          font-size: ${dimensions.fontSizeM};
          padding: 10.5px ${dimensions.spaceS};
        `;
    }
  }}

  ${p =>
    p.disabled &&
    css`
      &,
      &:hover {
        background: ${colors.cGray300};
        color: ${colors.cWhite};
        cursor: auto;
      }
    `}
`;
