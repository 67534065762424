import styled from 'styled-components/macro';
import {colors, dimensions, ToolBar} from '@bettermarks/bm-ui-components';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {ReplaySubject, Subscription} from 'rxjs';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useRoutes} from '../services/route.service';
import {useScrolling} from '../services/scrolling.service';
import {useStorage} from '../services/pt-storage.service';
import {MainNavigation} from './MainNavigation';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  background: ${colors.cWhite};
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const MainHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 0;
`;

export const Grid = styled.div<{
  padding?: boolean;
  maxCols?: number;
  maxWidth?: number;
}>`
  /* using css grid to adapt to the max possible number of columns for each viewport width without the need of media queries */
  --n: ${p => p.maxCols || 4};
  --g: ${dimensions.spaceM};
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      max(
        ${p => p.maxWidth || 250}px,
        (100% - (var(--n) - 1) * ${dimensions.spaceM}) / var(--n)
      ),
      1fr
    )
  );
  grid-gap: ${dimensions.spaceXl} ${dimensions.spaceM};
  padding: ${p => (p.padding ? `${dimensions.spaceM}` : 0)};
`;

export const Group: any = styled.section<{disablePadding?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${p => (!p.disablePadding ? `0 ${dimensions.spaceM}` : 0)};

  p {
    margin: 0;
  }

  & > & {
    padding: 0;
  }

  ${Grid} + ${Grid} {
    margin-top: ${dimensions.spaceXl};
  }
`;

const MainScroll = styled.main<{paddingBottom?: boolean}>`
  overflow-y: auto;
  padding-bottom: ${p => (p.paddingBottom ? '64px' : 0)};
  height: 100%;
  position: relative;
  ${Group} + ${Group} {
    margin-top: ${dimensions.spaceXl};
  }

  .hidden {
    display: none;
  }
`;

export const TextModule = styled.div`
  display: flex;
  padding: 16px 0 16px 36px;
  p {
    padding: 0;
    margin: 0;
    line-height: 26px;
    flex: 1 1 auto;
  }

  img {
    max-width: 100%;
    align-self: center;
    flex: 0 0 auto;
  }

  @media (min-width: 768px) {
    p + img {
      margin-left: ${dimensions.spaceM};
    }
  }
  @media (max-width: 767px) {
    p + img {
      margin-top: ${dimensions.spaceM};
    }

    flex-direction: column;
  }
`;

const LayoutHeaderSubject = new ReplaySubject<ReactElement[]>(1);

export const LayoutComponentService = {
  set header(components: ReactElement[]) {
    LayoutHeaderSubject.next(components);
  }
};

export const HeaderElements: React.FC = () => {
  const [headerElements, setHeader] = useState<ReactElement[]>([]);
  useEffect(() => {
    const subscr = LayoutHeaderSubject.subscribe(elems => setHeader(elems));
    return () => subscr.unsubscribe();
  }, []);
  return <>{headerElements}</>;
};

export const Layout: React.FC<{customToolbar?: any; paddingBottom?: boolean}> =
  ({children, customToolbar, paddingBottom}) => {
    const MainRef = useRef(null);
    const {pathname} = useLocation();

    const RouterService = useRoutes();
    const ScrollingService = useScrolling();
    const StorageService = useStorage();
    const scrollPosKey = `SCROLL_POS__${pathname}`;
    let scrollSubscriptions: Subscription[] = [];

    useEffect(() => {
      if (MainRef.current) {
        const main: HTMLElement = MainRef.current;
        const scrollTop = StorageService.load<string>(scrollPosKey) || '0';
        setTimeout(() => (main.scrollTop = parseInt(scrollTop)));
        scrollSubscriptions =
          (RouterService.routeShouldRestoreScrollPos(pathname) && [
            ScrollingService.handleScroll(
              main,
              window.location.href,
              scrollPosKey
            ),
            ScrollingService.scrollingPosition$().subscribe(
              () => (main.scrollTop = 0)
            )
          ]) ||
          [];
      }

      return () => {
        scrollSubscriptions.forEach(s => s.unsubscribe());
      };
    }, [pathname]);

    return (
      <MainContainer>
        <MainHeader>
          <MainNavigation key={'main-nav'} />
          <HeaderElements />
          {customToolbar}
        </MainHeader>
        <MainScroll paddingBottom={paddingBottom} ref={MainRef}>
          {children}
        </MainScroll>
      </MainContainer>
    );
  };

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 64px;
  height: 100%;
`;

export const StyledToolBar = styled(ToolBar)`
  border-bottom: 1px solid rgb(153, 153, 153);
`;
