import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const WhiteboardIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => (
  <svg
    width='26'
    height='20'
    viewBox='0 0 26 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.5 1.5V15.5H13.5H12.5H1.5V1.5H24.5ZM12.5 16.5H0.5V0.5H25.5V16.5H13.5V18.5H18.5V19.5H13.5H12.5H7.5V18.5H12.5V16.5Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);
