import styled from 'styled-components';
import {colors, dimensions} from '@bettermarks/bm-ui-components';

export const CounterBadgeAmount = styled.div`
  line-height: ${dimensions.lineHeightXs};
  text-align: center;
`;

export const Notification = styled.div`
  width: ${dimensions.spaceXl};
  height: ${dimensions.spaceXl};
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-size: ${dimensions.fontSizeS};
  background: ${colors.cGray700};
  color: ${colors.cWhite};
`;
