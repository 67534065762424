import styled from 'styled-components/macro';
import {dimensions} from '@bettermarks/bm-ui-components';

export interface CounterProps {
  amount: Readonly<number>;
  label: Readonly<string>;
}

const CounterContainer = styled.div`
  font-size: ${dimensions.fontSizeM};
  display: flex;
  width: 100%;
  padding: ${dimensions.spaceM};
`;

export const Counter: React.FC<CounterProps> = ({amount, label}) => (
  <CounterContainer>
    {amount} {label}
  </CounterContainer>
);
