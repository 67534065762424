import {Decoration, IconBtnStyle} from '@bettermarks/bm-ui-components';
import {ModuleHeader} from '../ModuleHeader';

import styled, {css} from 'styled-components';
import {StyledFC, colors, dimensions} from '@bettermarks/bm-ui-components';

import {DetailProps} from './types';
import {CalendarIcon, CaretIcon, Orientation} from '../Icons';
import {Knob, KnobSize, KnobType} from '../Knob';
import {CloseIcon} from '../Icons/Close';

const _DetailHeader: StyledFC<{modalTitle?: string; onClose: () => void}> = ({
  className,
  modalTitle,
  onClose
}) => {
  return (
    <header className={className}>
      {modalTitle}
      <Knob
        type={KnobType.ghost}
        size={KnobSize.medium}
        iconAppended={<CloseIcon />}
        onClick={onClose}
      />
    </header>
  );
};

export const DetailHeader = styled(_DetailHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${dimensions.spaceS};
  position: relative;
  border-bottom: 1px solid #dddddd;
  min-height: 52px;
  ${Knob} {
    position: absolute;
    top: 8.5px;
    right: 8.5px;
  }
`;

const Examples = styled.section`
  overflow-y: auto;
`;

const DetailContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${dimensions.spaceXl} ${dimensions.spaceM};
  position: relative;
  z-index: 0;
`;

const DetailHeadline = styled.h6`
  font-size: ${dimensions.fontSizeM};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceS};
  color: ${colors.cGray800};
`;

const DefinitionList = styled.dl`
  margin: 0;
  padding: 0;
  span + span {
    margin: ${dimensions.spaceL} 0 0;
    display: flex;
    flex-direction: column;
  }
`;

const Term = styled.dt`
  font-size: ${dimensions.fontSizeS};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceXs};
  padding: 0;
`;
const Desc = styled.dd`
  margin: 0;
  padding: 0;
  overflow-x: scroll;
`;

const DetailFooter = styled.footer`
  padding: ${dimensions.spaceM};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  ${Knob} {
    min-height: 40px;
    padding-top: ${dimensions.spaceXs};
    padding-bottom: ${dimensions.spaceXs};
  }
`;

const _DetailTile: StyledFC<
  Omit<DetailProps, 'onOpenModuleDetails' | 'onToggleActionTip'>
> = ({
  className,
  isActive,
  color,
  modalTitle,
  title,
  image,
  objectives,
  preknowledge,
  examples,
  onClick,
  onClose,
  isPlanned,
  onAddToPlanning,
  onOpenModule,
  onOpenPlanning,
  context
}) => {
  return isActive ? (
    <div className={className} onClick={onClick}>
      <DetailHeader modalTitle={modalTitle} onClose={onClose} />

      <DetailContent>
        <ModuleHeader
          color={color}
          title={title}
          image={image}
          objectives={objectives}
          preknowledge={preknowledge}
          context={context}
        />

        {examples && (
          <Examples>
            <DetailHeadline>Beispielaufgaben Vorschau</DetailHeadline>
            <DefinitionList>
              {examples.map((example: any, i: number) => (
                <span key={i}>
                  <Term>Aufgabe {i + 1}</Term>
                  <Desc>
                    <img
                      draggable='false'
                      src={example.image}
                      alt={`Aufgabe-${i + 1}`}
                    />
                  </Desc>
                </span>
              ))}
            </DefinitionList>
          </Examples>
        )}
      </DetailContent>
      <DetailFooter>
        <Knob
          {...((isPlanned && {
            label: 'In der Planung ansehen',
            iconPrepended: (
              <CalendarIcon
                fill={colors.cBlue700}
                decoration={Decoration.checkmark}
              />
            ),
            maxHeight: 40,
            iconAppended: <CaretIcon orientation={Orientation.right} />,
            colorizeIcon: false,
            onClick: onOpenPlanning
          }) || {
            label: 'Zur Planung hinzufügen',
            iconPrepended: (
              <CalendarIcon
                width={24}
                height={24}
                fill={colors.cBlue700}
                decoration={Decoration.plus}
              />
            ),
            onClick: onAddToPlanning
          })}
          type={KnobType.ghost}
          size={KnobSize.medium}
        />
        <Knob
          label='Öffnen'
          type={KnobType.button}
          maxHeight={40}
          iconAppended={
            <CaretIcon fill={colors.cWhite} orientation={Orientation.right} />
          }
          onClick={onOpenModule}
        />
      </DetailFooter>
    </div>
  ) : (
    <></>
  );
};

export const DetailTile = styled(_DetailTile)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: ${colors.cWhite};
  transition: all 0.5s ease-in-out;
  position: fixed;
  @media (max-height: 600px) {
    max-height: 90vh;
  }

  @media (min-height: 601px) {
    max-height: 560px;
  }

  @media (min-width: 768px) {
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 767px) {
    min-width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${p =>
    p.isActive
      ? css`
          opacity: 1;
          visibility: visible;
          z-index: 10;
        `
      : css`
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
        `};
`;

export default DetailTile;
