import {
  ButtonLayout,
  colors,
  dimensions,
  Footer,
  ModuleKnowledgeIntro,
  StyledFC,
  TileImageItem,
  TileTextItem,
  ModuleHeader,
  CaretRightIcon,
  Knob,
  KnobSize,
  KnobType,
  DetailHeader,
  FlexAlign
} from '@bettermarks/bm-ui-components';
import {AnimatePresence, motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';
import {ActivityType} from '../Lob/types';

const ModalContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 ${dimensions.spaceM};
`;

const ModalTabs = styled.aside``;

const ModalPanels = styled.div``;

const ModalPanel = styled.div``;

interface TabNavItemProps {
  id: string;
  title: any;
  active: boolean;
  activeTab: any;
  setActiveTab: any;
  icon?: any;
}

const Li = styled.li<{active?: boolean}>`
  outline: 0;
  padding: 0 20px 0 12px;
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  ${p =>
    p.active
      ? css`
          background-color: ${colors.cBlue200};
          border-color: ${colors.cBlue700};
          color: ${colors.cBlue700};
        `
      : css`
          background-color: ${colors.cWhite};
          border-color: ${colors.cGray700};
          color: ${colors.cGray700};
        `};
`;

const _TabNavItem: StyledFC<TabNavItemProps> = ({
  className,
  id,
  title,
  activeTab,
  setActiveTab
}) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <React.Fragment>
      <Li className={className} onClick={handleClick} active={activeTab === id}>
        {title}
      </Li>
    </React.Fragment>
  );
};

export interface ModalProps {
  activityType: ActivityType;
  fems?: any[];
  modalOpen: boolean;
  modalTitle?: string;
  onClose: () => void;
}

const _TaskModal: StyledFC<ModalProps> = ({
  activityType,
  fems,
  modalOpen,
  modalTitle,
  onClose
}) => {
  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div>
          <DetailHeader modalTitle={modalTitle} onClose={onClose} />
          <ModalContent>
            {activityType === ActivityType.FemList && (
              <>
                {fems?.map(fem => (
                  <ModalTabs key={fem.url}>{fem.title}</ModalTabs>
                ))}

                <ModalPanels>
                  {fems?.map(fem => (
                    <ModalPanel key={fem.url} id={fem.url}>
                      {fem.title}
                    </ModalPanel>
                  ))}
                </ModalPanels>
              </>
            )}
          </ModalContent>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const TaskModal = styled(_TaskModal)``;
