import React, {useEffect, useState} from 'react';
import styled, {CSSProperties} from 'styled-components/macro';
import {PlanningItemProps} from './types';
import {
  ActionTip,
  colors,
  dimensions,
  DocumentPreviewIcon,
  DragHandleIcon,
  HidePasswordIcon,
  Knob,
  KnobType,
  RemoveCalendarItemIcon,
  ShowPasswordIcon,
  StyledFC
} from '@bettermarks/bm-ui-components';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {
  ActionButton,
  Actions,
  Details,
  DragButton,
  Icon,
  SuperTopic,
  Topic
} from './SortableRowItem.sub-components';
import {useOverlay} from '../../components/Overlay';

const ActionTipWrapper = styled.div`
  padding-top: 25px;
  ${ActionTip} {
    width: 350px;
    right: 10px;
    left: auto;
    z-index: 8;
  }

  ${ActionTip}::before,
  ${ActionTip}::after {
    left: 272px;
  }
`;

const _RowItem: StyledFC<PlanningItemProps> = ({
  id,
  isDragging,
  className,
  icon,
  moduleName,
  supertopic,
  color,
  visible,
  onPreview,
  onRemove,
  onVisibilityChange,
  onOpenModule,
  removeSelf
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({
      id
    });

  const OverlayService = useOverlay();

  const sortingStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    borderTop: (isDragging && `1px solid ${colors.cGray400}`) || '',
    transition
  };
  const [style, setStyle] = useState<CSSProperties>({});
  const [tipVisibility, setTipVisibilityTo] = useState(false);

  const removeAndHide = () => {
    setTipVisibilityTo(false);
    OverlayService.deactivate();
    setStyle({
      animation: 'remove 0.5s forwards',
      overflow: 'hidden'
    });
    onRemove();
  };

  const handleRemoveFromPlanning = (id: string, isOpen: boolean) => {
    setTipVisibilityTo(isOpen);
    if (isOpen) {
      setStyle({zIndex: 7, position: 'relative'});
      OverlayService.fadeIn(() => {
        setStyle({});
        setTipVisibilityTo(false);
        OverlayService.deactivate();
      });
    } else {
      OverlayService.deactivate();
      setTimeout(() => setStyle({}), 800);
    }
  };

  useEffect(() => {
    removeSelf &&
      setStyle({
        animation: 'remove 0.5s forwards 0.3s',
        overflow: 'hidden'
      });
  }, [removeSelf]);

  const stopProp = (e: Event, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    callback();
    return false;
  };

  return (
    <li
      className={className}
      ref={setNodeRef}
      style={{...sortingStyle, ...style}}
      onClick={onOpenModule}
      {...attributes}
    >
      <ActionButton
        onClick={(e: any) => stopProp(e, () => onVisibilityChange(!visible))}
      >
        {visible ? (
          <HidePasswordIcon width={32} height={24} fill={colors.cBlue700} />
        ) : (
          <ShowPasswordIcon width={32} height={24} fill={colors.cBlue700} />
        )}
      </ActionButton>
      <Icon color={color} visible={visible}>
        <img src={icon} alt={moduleName} />
      </Icon>
      <Details>
        <Topic visible={visible}>{moduleName}</Topic>
        <SuperTopic>{supertopic}</SuperTopic>
      </Details>
      <Actions>
        <ActionButton onClick={(e: any) => stopProp(e, onPreview)}>
          <DocumentPreviewIcon />
        </ActionButton>
        <ActionButton
          onClick={(e: any) =>
            stopProp(e, () => handleRemoveFromPlanning(id, true))
          }
        >
          <RemoveCalendarItemIcon />
        </ActionButton>
        <DragButton {...listeners}>
          <DragHandleIcon />
        </DragButton>
      </Actions>
      <ActionTipWrapper>
        <ActionTip
          dialogue={'Möchten Sie dieses Modul aus der Planung entfernen?'}
          activeTip={tipVisibility}
          tipPosition={272}
          closeLabel={'Abbrechen'}
          onCloseTip={(e: Event) =>
            stopProp(e, () => handleRemoveFromPlanning(id, false))
          }
          buttonLayout='row'
          actionButton={
            <Knob
              type={KnobType.button}
              label='Löschen'
              onClick={(e: Event) => stopProp(e, removeAndHide)}
            />
          }
        />
      </ActionTipWrapper>
    </li>
  );
};

export const PlanningRowItem = styled(_RowItem)`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  /* padding: ${dimensions.spaceS}; */
  border-top: 1px solid ${colors.cGray400};
  border-bottom: 1px solid ${colors.cGray400};
  margin-bottom: -1px;
  background-color: #fff;
  ${Topic} + ${SuperTopic} {
    margin: ${dimensions.spaceXxs} 0 0;
  }

  ${Icon} {
    margin-left: ${dimensions.spaceM};
  }

  z-index: ${p => (p.isDragging && 10) || 0};
`;
