import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const CloseIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 14}
    height={height || 14}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.3535 0.353516L6.70707 5.99996L1.06062 0.353516L0.353516 1.06062L5.99996 6.70707L0.353516 12.3535L1.06062 13.0606L6.70707 7.41418L12.3535 13.0606L13.0606 12.3535L7.41418 6.70707L13.0606 1.06062L12.3535 0.353516Z'
      fill={fill || colors.cBlue700}
    />
  </svg>
);
