import React, {useEffect, useState, useCallback} from 'react';
import styled, {css} from 'styled-components';
import {useNavigate, useParams} from 'react-router-dom';

import {ToastContainer, toast, Slide} from 'react-toastify';
import '../../themes/toastify/main.scss';

import {
  ActivityType,
  dimensions,
  ModuleDetailProps,
  ModuleHeader,
  ModuleHeading,
  TileImageItem
} from '@bettermarks/bm-ui-components';

import {Lob} from '../../components/Lob/Lob';

import {
  Content,
  Group,
  Layout,
  LayoutComponentService,
  TextModule
} from '../../components/LayoutComponents';
import {useLibrary} from '../../services/library.service';
import {Module, ModuleContext, ModuleChapter} from '../../types';
import {handleAlertMessage} from '../../helpers/Alert';
import {Modal} from '../../components/modal/Modal';
import {Overlay, useOverlay} from '../../components/Overlay';
import {ModuleBar} from './ModuleBar';
import libraryMappings from '../../data/libraries/color-and-icon-mappings.json';
import {useRoutes} from '../../services/route.service';
import {usePlanning} from '../../services/planning.service';
import {useStorage} from '../../services/pt-storage.service';
import AllocateToolbar from '../../components/AllocateToolbar/AllocateToolbar';
import {CheckBox} from '../../components/Checkbox';
import {AllocationModal} from '../../components/modal/AllocationModal';

import {TaskModal} from '../../components/modal/TaskModal';
import useWindowDimensions from '../../helpers/useWindowDimensions';

const HeadingGroup = styled.div`
  flex: 1;
`;

const StyledModuleHeading = styled(ModuleHeading)<{gap?: boolean}>`
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0;
  ${p =>
    p.gap &&
    css`
      padding-left: 36px;
    `}

  ${CheckBox} {
    margin-right: 16px;
  }
  border: none;
  ${HeadingGroup} {
    border-bottom: 1px solid #ccc;
    padding: ${dimensions.spaceM} ${dimensions.spaceM} ${dimensions.spaceM} 0;
  }
`;

const AllocateOverlay = styled.div<{active?: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);

  ${p =>
    p.active
      ? css`
          visibility: visible;
          opacity: 1;
          transition: all 1s ease-in-out;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: all 600ms ease-out;
        `};
`;

const StyledModuleHeader = styled(ModuleHeader)`
  padding-bottom: 0;
  box-shadow: none;
`;

export const ModulePage: React.FC<{context: ModuleContext}> = ({context}) => {
  const [chapterIntro, setModuleIntro] = useState<ModuleDetailProps>({
    preknowledge: [],
    objectives: [],
    examples: []
  });

  const OverlayService = useOverlay(),
    RouteService = useRoutes(),
    LibraryService = useLibrary(),
    PlanningService = usePlanning(),
    navigate = useNavigate(),
    StorageService = useStorage();

  const [module, setModule] = useState<Module>();
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    modalTitle?: any;
    title?: string;
    previewImages?: TileImageItem[];
    meta?: string;
    contentTasks?: any[];
    fems?: any;
    caption: string;
  }>({
    title: '',
    modalTitle: 'ExerciseList',
    isOpen: false,
    previewImages: [],
    meta: '',
    contentTasks: [],
    fems: [],
    caption: ''
  });

  const [taskModalConfig, setTaskModalConfig] = useState<{
    isOpen: boolean;
    activityType: ActivityType;
    modalTitle?: any;
    fems?: any;
    contentTasks?: any[];
  }>({
    isOpen: false,
    activityType: ActivityType.ExerciseList,
    modalTitle: '',
    contentTasks: []
  });

  const openTaskModal = (
    isOpen: boolean,
    activityType: ActivityType,
    modalTitle?: any,
    fems?: any,
    contentTasks?: any[]
  ): void => {
    //OverlayService.fadeIn(() => closeModal());
    setTaskModalConfig({
      isOpen: true,
      activityType,
      modalTitle,
      fems,
      contentTasks
    });
  };

  const closeTaskModal = () => {
    setTaskModalConfig({
      isOpen: false,
      activityType: ActivityType.ExerciseList
    });
    OverlayService.deactivate();
    // setActiveLob('');
  };

  const [moduleIconAndColor, setIconColor] = useState<{
    color: string;
    icon: string;
  }>({
    color: '',
    icon: ''
  });

  const {libraryId, moduleId} = {
    libraryId: '',
    moduleId: '',
    ...useParams<{libraryId: string; moduleId: string}>()
  };
  const updateModuleBar = () =>
    (LayoutComponentService.header = [
      <ModuleBar
        libraryId={libraryId}
        context={context}
        key={'module-bar'}
        onAddToPlanning={addToPlanning}
        onRemoveFromPlanning={removeFromPlanning}
        isPlanned={PlanningService.itemIsPlanned(moduleId)}
      />
    ]);

  const addToPlanning = () => {
    PlanningService.add(moduleId, libraryId, moduleIconAndColor.color);
    updateModuleBar();
  };

  const removeFromPlanning = () => {
    navigate('/planning', {state: {removeFromPlanning: moduleId}});
  };

  useEffect(() => {
    const m = LibraryService.getModule(moduleId);
    LibraryService.library$(StorageService.getVersion(), libraryId).subscribe(
      library => {
        const domain = LibraryService.getDomainForModule(library, m);
        const icon = (
          libraryMappings.books.find(b => b.id === moduleId) || {
            bookIcon: 'capital-X.svg'
          }
        ).bookIcon;
        setIconColor({color: domain?.color || '#666', icon});
      }
    );
    setModuleIntro(LibraryService.chapterIntro(moduleId));
    setModule(m);

    RouteService.setContentRoute(RouteService.libraryRoute(libraryId));
    updateModuleBar();
  }, [moduleId]);

  const closeModal = () => {
    setModalConfig({isOpen: false, caption: ''});
    OverlayService.deactivate();
    setActiveLob('');
  };

  const openLobDetails = (
    title: string,
    modalTitle: any,
    previewImages: TileImageItem[],
    meta: string,
    contentTasks: any[],
    caption: string
  ): void => {
    //OverlayService.fadeIn(() => closeModal());
    setModalConfig({
      title,
      modalTitle,
      previewImages,
      meta,
      contentTasks,
      isOpen: true,
      caption
    });
  };

  const openChapterPreview = (
    title: string,
    modalTitle: any,
    contentListRef: string,
    meta: string,
    contentTasks: any[],
    caption: string
  ) => {
    openLobDetails(
      title,
      modalTitle,
      LibraryService.getPreviewExerciseList(contentListRef),
      meta,
      contentTasks,
      caption
    );
  };

  const [activeLob, setActiveLob] = useState<string>('');

  const handleActiveLob = (
    e: string,
    title: string,
    modalTitle: any,
    contentListRef: string,
    meta: string,
    contentTasks: any[],
    caption: string
  ) => {
    activeLob !== e ? setActiveLob(e) : null;

    openChapterPreview(
      title,
      modalTitle,
      contentListRef,
      meta,
      contentTasks,
      caption
    );
  };

  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);

  const [lobList, setLobList] = useState<string[]>([]);

  function handleCheckLob(itemId: string) {
    if (checkedItemIds.indexOf(itemId) > -1) {
      setCheckedItemIds(prev => prev.filter(i => i !== itemId));
    } else {
      setCheckedItemIds(prev => [...prev, itemId]);
    }
  }

  const updateLobList = (id: string) => {
    if (checkedItemIds.includes(id)) {
      setLobList(lobList.filter(tool => tool != id));
    } else {
      setLobList(lobList => [...lobList, id]);
    }
  };

  // console.log('Lob List', checkedItemIds);

  // console.log('assigmnent list', lobList);

  const [openAllocate, setOpenAllocate] = useState(false);

  const close = () => setOpenAllocate(!openAllocate);

  const resetAllocation = () => {
    setCheckedItemIds([]);
    setLobList([]);
    setOpenAllocate(false);
  };

  const {position} = useWindowDimensions(1400);
  const getCheckedItems = (chapter: ModuleChapter) => {
    for (let i = 0; i < chapter?.chapters?.length; i++) {
      if (checkedItemIds.indexOf(chapter?.chapters[i].name) === -1)
        return false;
    }
    return true;
  };
  const removeCheckedItem = (chapter: ModuleChapter) => {
    const _checkedItemIds = [...checkedItemIds];
    for (let i = 0; i < chapter?.chapters?.length; i++) {
      const index = _checkedItemIds.indexOf(chapter.chapters[i].name);
      if (index > -1) {
        _checkedItemIds.splice(index, 1);
      }
    }
    return _checkedItemIds;
  };
  function handleCheckChapter(chapter: ModuleChapter) {
    if (getCheckedItems(chapter)) {
      setCheckedItemIds(removeCheckedItem(chapter));
    } else {
      setCheckedItemIds([
        ...new Set([
          ...chapter.chapters.map((chapter: any) => chapter.name),
          ...checkedItemIds
        ])
      ]);
    }
  }
  return (
    <>
      {module && (
        <Layout
          customToolbar={
            <AllocateToolbar
              count={checkedItemIds.length}
              allocate={() => {
                setOpenAllocate(true);
                closeModal();
              }}
              deselect={resetAllocation}
            />
          }
        >
          <Content>
            <StyledModuleHeader
              color={moduleIconAndColor.color}
              title={module.name}
              margin={dimensions.spaceXl}
              objectives={
                chapterIntro.objectives.length > 0
                  ? chapterIntro.objectives
                  : null
              }
              preknowledge={
                chapterIntro.preknowledge.length > 0
                  ? chapterIntro.preknowledge
                  : null
              }
              image={`/topic-icons/${moduleIconAndColor.icon}`}
              imageSize={200}
              context='module'
            />

            {module?.id === 'd2ec79_DE_bettermarks_de' ? (
              <>
                <Group>
                  {module?.chapters?.slice(0, 2).map(chapter => (
                    <Group key={chapter.id}>
                      <StyledModuleHeading
                        as='h4'
                        border
                        gap={chapter?.chapters?.length < 2}
                      >
                        {chapter?.chapters?.length > 1 && (
                          <CheckBox
                            checked={getCheckedItems(chapter)}
                            onClick={() => handleCheckChapter(chapter)}
                          />
                        )}

                        <HeadingGroup>
                          {chapter.caption} {chapter.name}
                        </HeadingGroup>
                      </StyledModuleHeading>
                      {chapter?.chapters?.map(item => (
                        <Lob
                          key={item.id}
                          caption={item.caption}
                          title={item.name}
                          checked={checkedItemIds.indexOf(item.name) > -1}
                          active={activeLob === item.name}
                          activeIndex={activeLob}
                          changeHandler={() => {
                            handleCheckLob(item.name);
                            updateLobList(item.name);
                          }}
                          meta={item.meta}
                          actionOpenDetails={() => {
                            handleActiveLob(
                              item.name,
                              item.name,
                              item.chapters[0].content_type,
                              item.chapters[0].content_list_ref,
                              item.meta,
                              item.chapters,
                              item.caption
                            );
                            console.log('fems', item.chapters[0].fems);
                          }}
                          activityType={item.chapters[0].content_type}
                          contentTasks={item.chapters}
                        />
                      ))}
                    </Group>
                  ))}
                </Group>{' '}
                <Group>
                  <StyledModuleHeading as='h4' border gap>
                    <HeadingGroup>Einleitung</HeadingGroup>
                  </StyledModuleHeading>
                  <TextModule>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Libero enim sed faucibus turpis in eu mi bibendum
                      neque. Aliquet sagittis id consectetur purus ut faucibus
                      pulvinar elementum integer. Ultricies tristique nulla
                      aliquet enim. Eu mi bibendum neque egestas congue quisque
                      egestas.
                      <br />
                      Et leo duis ut diam quam nulla. Gravida neque convallis a
                      cras. Sit amet consectetur adipiscing elit ut aliquam
                      purus sit. Sit amet mattis vulputate enim nulla aliquet
                      porttitor lacus. Adipiscing commodo elit at imperdiet dui.
                      Amet nulla facilisi morbi tempus iaculis urna id volutpat.
                      <br />
                      Erat nam at lectus urna. Dolor sit amet consectetur
                      adipiscing. Egestas purus viverra accumsan in nisl nisi
                      scelerisque eu. Aenean sed adipiscing diam donec
                      adipiscing tristique risus nec feugiat. Cursus euismod
                      quis viverra nibh cras pulvinar mattis nunc.
                    </p>

                    <img
                      src='/book-view/FuV3Koe3Kug10.IMG1_300x300.jpg'
                      alt='Globe'
                    />
                  </TextModule>
                </Group>{' '}
                <Group>
                  {module?.chapters?.slice(2, -1).map(chapter => (
                    <Group key={chapter.id}>
                      <StyledModuleHeading
                        as='h4'
                        border
                        gap={chapter?.chapters?.length < 2}
                      >
                        {chapter?.chapters?.length > 1 && (
                          <CheckBox
                            checked={getCheckedItems(chapter)}
                            onClick={() => handleCheckChapter(chapter)}
                          />
                        )}

                        <HeadingGroup>
                          {chapter.caption} {chapter.name}
                        </HeadingGroup>
                      </StyledModuleHeading>
                      {chapter?.chapters?.map(item => (
                        <Lob
                          key={item.id}
                          caption={item.caption}
                          title={item.name}
                          checked={checkedItemIds.indexOf(item.name) > -1}
                          active={activeLob === item.name}
                          activeIndex={activeLob}
                          changeHandler={() => {
                            handleCheckLob(item.name);
                            updateLobList(item.name);
                          }}
                          meta={item.meta}
                          actionOpenDetails={() => {
                            handleActiveLob(
                              item.name,
                              item.name,
                              item.chapters[0].content_type,
                              item.chapters[0].content_list_ref,
                              item.meta,
                              item.chapters,
                              item.caption
                            );
                            console.log('fems', item.chapters[0].fems);
                          }}
                          activityType={item.chapters[0].content_type}
                          contentTasks={item.chapters}
                        />
                      ))}
                    </Group>
                  ))}
                </Group>
              </>
            ) : (
              <>
                {module?.chapters?.slice(0, 1).map(
                  chapter =>
                    chapter?.chapters?.length > 0 &&
                    module?.chapters?.slice(0, 1).map(chapter => (
                      <Group key={chapter.id}>
                        <StyledModuleHeading
                          as='h4'
                          border
                          gap={chapter?.chapters?.length < 2}
                        >
                          {chapter?.chapters?.length > 1 && (
                            <CheckBox
                              checked={getCheckedItems(chapter)}
                              onClick={() => handleCheckChapter(chapter)}
                            />
                          )}

                          <HeadingGroup>
                            {chapter.caption}{' '}
                            {chapter.name.replace('Einleitung', 'Vorwissen')}
                          </HeadingGroup>
                        </StyledModuleHeading>
                        {chapter?.chapters?.map(item => (
                          <Lob
                            key={item.id}
                            caption={item.caption}
                            title={item.name}
                            checked={checkedItemIds.indexOf(item.name) > -1}
                            active={activeLob === item.name}
                            activeIndex={activeLob}
                            changeHandler={() => {
                              handleCheckLob(item.name);
                              updateLobList(item.name);
                            }}
                            meta={item.meta}
                            actionOpenDetails={() => {
                              handleActiveLob(
                                item.name,
                                item.name,
                                item.chapters[0].content_type,
                                item.chapters[0].content_list_ref,
                                item.meta,
                                item.chapters,
                                item.caption
                              );
                              console.log('fems', item.chapters[0].fems);
                            }}
                            activityType={item.chapters[0].content_type}
                            contentTasks={item.chapters}
                          />
                        ))}
                      </Group>
                    ))
                )}
                <Group>
                  {module?.chapters?.slice(1).map(chapter => (
                    <Group key={chapter.id}>
                      <StyledModuleHeading
                        as='h4'
                        border
                        gap={chapter?.chapters?.length < 2}
                      >
                        {chapter?.chapters?.length > 1 && (
                          <CheckBox
                            checked={getCheckedItems(chapter)}
                            onClick={() => handleCheckChapter(chapter)}
                          />
                        )}

                        <HeadingGroup>
                          {chapter.caption} {chapter.name}
                        </HeadingGroup>
                      </StyledModuleHeading>
                      {chapter?.chapters?.map(item => (
                        <Lob
                          key={item.id}
                          caption={item.caption}
                          title={item.name}
                          checked={checkedItemIds.indexOf(item.name) > -1}
                          active={activeLob === item.name}
                          activeIndex={activeLob}
                          changeHandler={() => {
                            handleCheckLob(item.name);
                            updateLobList(item.name);
                          }}
                          meta={item.meta}
                          actionOpenDetails={() => {
                            handleActiveLob(
                              item.name,
                              item.name,
                              item.chapters[0].content_type,
                              item.chapters[0].content_list_ref,
                              item.meta,
                              item.chapters,
                              item.caption
                            );
                            console.log('fems', item.chapters[0].fems);
                          }}
                          activityType={item.chapters[0].content_type}
                          contentTasks={item.chapters}
                        />
                      ))}
                    </Group>
                  ))}
                </Group>
              </>
            )}

            <Modal
              color={moduleIconAndColor.color}
              position={position}
              caption={modalConfig.caption}
              modalTitle={modalConfig.modalTitle}
              title={modalConfig.title || ''}
              examples={modalConfig.previewImages}
              modalOpen={modalConfig.isOpen}
              closeModal={closeModal}
              meta={modalConfig.meta}
              contentTasks={modalConfig.contentTasks}
              asDetailTooltip
              onStart={() => {
                alert('Aufgabenserie starten');
              }}
            />
            <TaskModal
              activityType={taskModalConfig.activityType}
              modalTitle={taskModalConfig.modalTitle}
              modalOpen={taskModalConfig.isOpen}
              fems={taskModalConfig.fems}
              onClose={closeTaskModal}
            />
          </Content>

          <AllocationModal
            modalOpen={openAllocate}
            modalTitle='To-dos erstellen und anpassen'
            closeModal={close}
            lobList={checkedItemIds}
            createAssignment={() => {
              toast.success(
                'Sie haben die ausgewählten Lernziele erfolgreich zugewiesen.'
              );
              close();
              resetAllocation();
            }}
            onRemoveMiniLob={() =>
              alert('diese Funktion steht noch nicht zur Verfügung!')
            }
          />
          <ToastContainer
            position='top-right'
            transition={Slide}
            hideProgressBar={true}
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Overlay />
          <AllocateOverlay active={openAllocate} />
        </Layout>
      )}
    </>
  );
};
