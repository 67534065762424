import styled, {css} from 'styled-components';
import {
  StyledFC,
  dimensions,
  colors,
  Knob,
  KnobType,
  KnobSize
} from '@bettermarks/bm-ui-components';
import {CheckBox} from '../Checkbox';
import {Notification} from '../Notification';
import {CloseIcon} from '../Icons/Close';
import {AnimatePresence, motion} from 'framer-motion';
import React from 'react';

interface AllocateToolbarProps {
  state?: 'initial' | 'lobSelected';
  count: number;
  deselect?: () => void;
  allocate?: () => void;
}

const StyledCheckBox = styled(CheckBox)`
  min-height: auto;
  & span {
    margin: 0 ${dimensions.spaceS};
  }
`;

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const InlineContainer = styled(Container)`
  gap: 12px;
`;

const RemoveIcon = styled.span`
  flex: 0 0 24px;
  border-radius: 50%;
  background-color: ${colors.cBlue700};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  oultine: 0;
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
  }
`;

const _AllocateToolbar: StyledFC<AllocateToolbarProps> = ({
  className,
  count,
  deselect,
  allocate
}) => {
  return (
    <div className={className}>
      <AnimatePresence>
        {count > 0 ? (
          <InlineContainer
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ease: 'anticipate', duration: 0.15}}
          >
            <Notification>{count}</Notification>
            {count > 1 ? 'ausgewählte Lernziele' : 'ausgewähltes Lernziel'} als
            To-dos zuweisen
            <Knob
              label='Zuweisen'
              type={KnobType.button}
              size={KnobSize.large}
              onClick={allocate}
            />
            <Knob
              label='Alle abwählen'
              type={KnobType.link}
              size={KnobSize.large}
              onClick={deselect}
              iconPrepended={
                <RemoveIcon>
                  <CloseIcon fill={colors.cWhite} />
                </RemoveIcon>
              }
            />
          </InlineContainer>
        ) : (
          <Container
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
          >
            Wählen sie mit <StyledCheckBox /> Lernziele aus, die sie Ihren
            Schülern zuweisen möchten.
          </Container>
        )}
      </AnimatePresence>
    </div>
  );
};

const AllocateToolbar = styled(_AllocateToolbar)`
  padding: 0 ${dimensions.spaceM};
  background-color: ${colors.cWhite};
  display: flex;
  box-shadow: inset 0 -1px 0 ${colors.cGray400};
  align-items: center;
  position: sticky;
  top: 0;
  min-height: 60px;
  z-index: 1;
`;

export default AllocateToolbar;
