import {colors, dimensions, StyledFC} from '@bettermarks/bm-ui-components';
import styled from 'styled-components';

export enum Kind {
  action = 'action'
}

type TeaserBannerProps = {
  kind: Kind;
  grow?: 'auto' | 'stretch';
  image?: string;
  imageMaxWidth?: number | string;
  alt?: string;
};

const _TeaserBanner: StyledFC<TeaserBannerProps> = ({
  className,
  image,
  children,
  alt
}) => {
  return (
    <div className={className}>
      {image && (
        <div className='col-image'>
          <img src={image} alt={alt} />
        </div>
      )}
      <div className='col-description'>{children}</div>
    </div>
  );
};

export const TeaserBanner = styled(_TeaserBanner)<
  Pick<TeaserBannerProps, 'kind' | 'grow'>
>`
  display: flex;
  background-color: ${colors.cBlue100};
  border: 1px solid;
  border-color: ${colors.cBlue700};
  padding: ${dimensions.spaceXl};
  gap: ${dimensions.spaceXl};
  img {
    max-width: ${p => (p.imageMaxWidth ? p.imageMaxWidth + 'px' : '100%')};
    height: auto;
    width: 100%;
  }
  .col-image {
    flex: 0 0 auto;
  }
  .col-description {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap ${dimensions.spaceS};
  }
  .col-description > * {
      margin: 0;
  }
  @media(max-width: 768px) {
      flex-direction: column
  }
 
`;
