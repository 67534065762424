import {colors, dimensions} from '@bettermarks/bm-ui-components';
import {Headline} from './librarySelectionSubComponents';
import {Kind, TeaserBanner} from '../../components/TeaserBanner';
import {CaretIcon, Orientation} from '../../components/Icons';
import {Knob, KnobSize, KnobType} from '../../components/Knob';
import styled from 'styled-components';

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 ${dimensions.spaceL};
  line-height: ${dimensions.lineHeightL2};
`;

export const WhiteboardTeaser = () => {
  return (
    <TeaserBanner
      kind={Kind.action}
      image='/bettermarks/whiteboard-illustration.svg'
      imageMaxWidth={200}
    >
      <Headline>Interaktive Tafelbilder für Ihren Themeneinstieg</Headline>
      <List>
        <li>
          Verstehensorientierte Visualisierungen für Whiteboards und Beamer
        </li>
        <li>Zum Austeilen an Ihre Schüler:Innen über QR-Codes</li>
        <li>Für unterschiedliche Lernlevel</li>
      </List>
      <Knob
        label='Zu den Tafelbildern'
        iconAppended={
          <CaretIcon orientation={Orientation.right} fill={colors.cWhite} />
        }
        type={KnobType.button}
        size={KnobSize.medium}
        onClick={() =>
          window.open('https://einstiege.bettermarks.com/', '_blank')
        }
      />
    </TeaserBanner>
  );
};
