import {
  filter,
  fromEvent,
  map,
  Observable,
  pairwise,
  Subject,
  Subscription,
  tap,
  throttleTime
} from 'rxjs';
import React, {Context, createContext, useContext} from 'react';
import {StorageService} from './pt-storage.service';

export interface ScrollFence {
  top: number;
  bottom?: number;
}

export enum ScrollFenceEvent {
  LEFT,
  ENTERED
}

export interface ScrollingService {
  registerScrollFence$: (
    id: string,
    scrollFence: ScrollFence
  ) => Observable<ScrollFenceEvent>;
  resetScrollPos: () => void;
  scrollingPosition$: () => Observable<number>;
  handleScroll: (
    scrollContainer: HTMLElement,
    path: string,
    scrollPosKey?: string
  ) => Subscription;
}

const setScrollingTo$ = new Subject<number>();
const scrolling$ = new Subject<number>();
// api methods
const scrollingService: ScrollingService = {
  resetScrollPos() {
    setScrollingTo$.next(0);
  },
  scrollingPosition$() {
    return setScrollingTo$.asObservable();
  },
  handleScroll(
    scrollContainer: HTMLElement,
    path: string,
    scrollPosKey?: string
  ) {
    let currentScrollPosition: number;
    const subscr = fromEvent<React.UIEvent<HTMLElement>>(
      scrollContainer,
      'scroll'
    )
      .pipe(
        throttleTime(10),
        tap(event =>
          scrolling$.next(
            (event.currentTarget.scrollTop / scrollContainer.scrollHeight) * 100
          )
        )
      )
      .subscribe(event => {
        path === event.currentTarget.baseURI &&
          (currentScrollPosition = event.currentTarget.scrollTop);
      });

    scrollPosKey &&
      subscr.add(() => {
        currentScrollPosition >= 0 &&
          StorageService.save(scrollPosKey, currentScrollPosition);
      });

    return subscr;
  },
  registerScrollFence$(id: string, scrollFence: ScrollFence) {
    scrollFence.bottom = scrollFence.bottom || 100;
    if (scrollFence.top < 0 || scrollFence.top > 100) {
      throw new Error('invalid topPercentage, must be 0 <= top <= 100');
    }

    if (scrollFence.bottom < scrollFence.top || scrollFence.bottom > 100) {
      throw new Error('invalid bottomPercentage, must be top < bottom <= 100');
    }

    return scrolling$.pipe(
      map(pos =>
        pos >= scrollFence.top &&
        (!scrollFence.bottom || pos <= scrollFence.bottom)
          ? 'WITHIN'
          : 'OUTSIDE'
      ),
      pairwise(),
      filter(([prev, current]) => prev !== current),
      map(([prev, current]) =>
        prev === 'WITHIN' && current === 'OUTSIDE'
          ? ScrollFenceEvent.LEFT
          : ScrollFenceEvent.ENTERED
      )
    );
  }
};

const ScrollingContext: Context<ScrollingService> =
  createContext<ScrollingService>(scrollingService);

export const useScrolling = () => useContext(ScrollingContext);

export const ScrollingServiceProvider: React.FC<Partial<ScrollingService>> = ({
  children,
  ...apiMethods
}) => {
  const api: ScrollingService = {
    resetScrollPos:
      apiMethods.resetScrollPos || scrollingService.resetScrollPos,
    scrollingPosition$:
      apiMethods.scrollingPosition$ || scrollingService.scrollingPosition$,
    handleScroll: apiMethods.handleScroll || scrollingService.handleScroll,
    registerScrollFence$:
      apiMethods.registerScrollFence$ || scrollingService.registerScrollFence$
  };

  return (
    <ScrollingContext.Provider value={api}>
      {children}
    </ScrollingContext.Provider>
  );
};
