import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const SignOutIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => {
  return (
    <svg
      width='19'
      height='16'
      viewBox='0 0 19 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.292969 -0.0292969H12.4271V4.85659H11.4271V0.970703H1.29297V15.0295H11.4271V11.1436H12.4271V16.0295H0.292969V-0.0292969Z'
        fill={fill || colors.cWhite}
      />
      <path
        d='M16.6116 8.5H8.98096V7.5H16.6117L14.1899 5.0782L14.897 4.37109L18.5259 7.99994L14.897 11.6288L14.1899 10.9217L16.6116 8.5Z'
        fill={fill || colors.cWhite}
      />
    </svg>
  );
};
