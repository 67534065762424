import styled from 'styled-components';
import {StyledFC, colors, dimensions} from '@bettermarks/bm-ui-components';
import {LibraryTileProps} from './types';
import {MetaLabel, Variant} from '../MetaLabel';
import {ModuleTitle} from '../ModuleTile/ModuleTile';

const TileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`;
const LibIcon = styled.div<Pick<LibraryTileProps, 'iconWidth' | 'iconPadding'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => (p.iconPadding ? `${dimensions.spaceS}` : 0)};
  height: 100%;
  flex-basis: ${p => (p.iconWidth ? p.iconWidth + 'px' : 'auto')};
  flex-shrink: 0;
  img {
    max-width: 100%;
  }
`;
const LibDetails = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  gap: ${dimensions.spaceXxs};
  ${TileRow} {
    gap: ${dimensions.spaceXs};
  }
`;
const _LibraryTile: StyledFC<LibraryTileProps> = ({
  className,
  icon,
  iconWidth,
  iconPadding,
  title,
  themeCount,
  classRange,
  beta
}) => {
  return (
    <div className={className}>
      <TileRow>
        {icon && (
          <LibIcon iconPadding={iconPadding} iconWidth={iconWidth}>
            {icon}
          </LibIcon>
        )}
        <LibDetails>
          <ModuleTitle>{title}</ModuleTitle>
          <TileRow>
            {beta && <MetaLabel label='beta' variant={Variant.solid} />}
            {themeCount && (
              <MetaLabel
                label={themeCount + ' ' + (themeCount > 1 ? 'Themen' : 'Thema')}
                variant={Variant.ghost}
              />
            )}
            {classRange && (
              <MetaLabel label={classRange} variant={Variant.ghost} />
            )}
          </TileRow>
        </LibDetails>
      </TileRow>
    </div>
  );
};

export const LibraryTile = styled(_LibraryTile)`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${dimensions.spaceM};
  border: 1px solid;
  position: relative;
  transition: box-shadow 0.35s ease-in-out;
  path {
    transition: all 0.5s ease-in;
    fill: ${p => (p.isActive ? `${colors.cBlue700}` : `${colors.cGray700}`)};
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 14px #e6e6e6;
  }

  border-color: ${p =>
    p.isActive ? `${colors.cBlue700}` : `${colors.cGray400}`};
  background-color: ${p =>
    p.isActive ? `${colors.cBlue200}` : `${colors.cWhite}`};
  border-radius: 5px;

  ${LibDetails} ${TileRow} {
    flex-wrap: wrap;
  }

  ${LibIcon} + ${LibDetails} {
    margin-left: ${dimensions.spaceM};
  }

  ${TileRow} + ${TileRow} {
    margin-top: ${dimensions.spaceXs};
  }
`;
