import {StyledFC, dimensions, colors} from '@bettermarks/bm-ui-components';
import styled, {css} from 'styled-components';

import {MetaLabelProps, Variant} from './types';

const _MetaLabel: StyledFC<MetaLabelProps> = ({className, label}) => {
  return <span className={className}>{label}</span>;
};

const SharedMetaLabelStyles = `padding: ${dimensions.spaceXxs} ${dimensions.spaceXs};
  border-radius: ${dimensions.spaceS};`;

export const MetaLabel = styled(_MetaLabel)<Pick<MetaLabelProps, 'variant'>>`
  font-size: ${dimensions.fontSizeXs};
  line-height: 100%;
  display: inline-flex;

  ${p => {
    switch (p.variant) {
      case Variant.solid:
        return css`
          ${SharedMetaLabelStyles}
          background: ${colors.cGray700};
          color: ${colors.cWhite};
        `;
      case Variant.outline:
        return css`
          ${SharedMetaLabelStyles}
          background: ${colors.cWhite};
          border: 1px solid ${colors.cGray700};
          color: ${colors.cGray700};
        `;
      case Variant.ghost:
        return css`
          color: ${colors.cGray700};
        `;
    }
  }}
`;
