import React from 'react';
import {colors} from '@bettermarks/bm-ui-components';

export const TextListIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => (
  <svg
    width={width || 32}
    height={height || 32}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.3238 17.6071L14.4006 13.6769L21.703 10.2983L18.3238 17.6071ZM12.6913 12.6842L7.91051 24.089L19.3089 19.3082L24.0961 7.90341L12.6913 12.6842ZM28.1601 15.3608H26.8801C26.5281 15.3608 26.2401 15.643 26.2401 16.0008C26.2401 16.3534 26.5281 16.6408 26.8801 16.6408H28.1601C28.5121 16.6408 28.8001 16.3534 28.8001 16.0008C28.8001 15.643 28.5121 15.3608 28.1601 15.3608ZM24.1471 23.2383C23.8975 22.9887 23.4943 22.9887 23.2383 23.2383C22.9887 23.4873 22.9887 23.8905 23.2383 24.1407L24.1471 25.0495C24.2687 25.1705 24.4351 25.2351 24.6015 25.2351C24.7615 25.2351 24.9279 25.1705 25.0495 25.0495C25.2991 24.7993 25.2991 24.3903 25.0495 24.1407L24.1471 23.2383ZM15.9994 26.2386C15.6474 26.2386 15.3594 26.5209 15.3594 26.8786V28.1586C15.3594 28.5113 15.6474 28.7986 15.9994 28.7986C16.3514 28.7986 16.6394 28.5113 16.6394 28.1586V26.8786C16.6394 26.5209 16.3514 26.2386 15.9994 26.2386ZM5.12028 15.3608H3.84028C3.48828 15.3608 3.20028 15.643 3.20028 16.0008C3.20028 16.3534 3.48828 16.6408 3.84028 16.6408H5.12028C5.47228 16.6408 5.76028 16.3534 5.76028 16.0008C5.76028 15.643 5.47228 15.3608 5.12028 15.3608ZM15.9994 5.75886C16.3514 5.75886 16.6394 5.4715 16.6394 5.11886V3.83886C16.6394 3.4811 16.3514 3.19886 15.9994 3.19886C15.6474 3.19886 15.3594 3.4811 15.3594 3.83886V5.11886C15.3594 5.4715 15.6474 5.75886 15.9994 5.75886ZM8.76118 8.75456C9.01078 8.50432 9.01078 8.10112 8.76118 7.85216L7.85238 6.94336C7.60278 6.69312 7.19958 6.69312 6.94998 6.94336C6.70038 7.19232 6.70038 7.60256 6.94998 7.85216L7.85238 8.75456C7.98038 8.88256 8.14038 8.94016 8.30678 8.94016C8.47318 8.94016 8.63318 8.88256 8.76118 8.75456ZM15.9998 30.7213C7.88463 30.7213 1.27983 24.1171 1.27983 16.0013C1.27983 7.88029 7.88463 1.28125 15.9998 1.28125C24.115 1.28125 30.7198 7.88029 30.7198 16.0013C30.7198 24.1171 24.115 30.7213 15.9998 30.7213ZM16 0C7.1808 0 0 7.1744 0 16C0 24.8198 7.1808 32 16 32C24.8256 32 32 24.8198 32 16C32 7.1744 24.8256 0 16 0Z'
      fill={fill || colors.cGray700}
    />
  </svg>
);
