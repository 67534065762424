import {ReactElement} from 'react';
import {
  ButtonLayout,
  colors,
  dimensions,
  Footer,
  ModuleKnowledgeIntro,
  StyledFC,
  TileImageItem,
  TileTextItem,
  ModuleHeader,
  CaretRightIcon
} from '@bettermarks/bm-ui-components';
import styled, {css} from 'styled-components/macro';
import {ModalDetails} from './modal.sub-components';
import {ContentType} from '../Lob/Lob';
import {ActivityType} from '../Lob/types';
import {DetailHeader} from './DetailHeader';
import {AnimatePresence, motion} from 'framer-motion';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  solid,
  regular,
  brands
} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Knob, KnobSize, KnobType} from '../Knob';

const {DetailContent, SubContent, DefinitionList, Term, Desc} = ModalDetails;

export interface ModalProps {
  className?: string;
  position?: number;
  color: string;
  modalOpen: boolean;
  modalTitle?: any;
  title: string;
  caption?: string;
  subtitle?: string;
  image?: string;
  closeModal: () => void;
  examples?: TileImageItem[];
  preknowledge?: TileTextItem[];
  objectives?: TileTextItem[];
  meta?: string;
  contentTasks?: any;
  footer?: ReactElement;
  asDetailTooltip?: boolean;
  onStart?: () => void;
}

const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.h3`
  font-size: ${dimensions.fontSizeL};
  line-height: 100%;
  margin: 0 0 ${dimensions.spaceM};
  font-weight: bold;
  width: 100%;
`;

interface TaskItemProps {
  openTaskPlayer: () => void;
  activityType: ActivityType;
}

const _TaskItem: StyledFC<TaskItemProps> = ({
  className,
  openTaskPlayer,
  activityType
}) => {
  return (
    <li className={className} onClick={openTaskPlayer}>
      <ContentType activityType={activityType} label useSmallIcons />
      <CaretRightIcon height={12} fill={colors.cBlue700} />
    </li>
  );
};

const TaskItem = styled(_TaskItem)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${dimensions.spaceXs} ${dimensions.spaceS};
  min-height: 44px;
  border-bottom: 1px solid ${colors.cGray400};
`;

const _TaskList: StyledFC<{contentTasks: any[]}> = ({
  className,
  contentTasks
}) => {
  return (
    <ul className={className}>
      {contentTasks.slice(1).map((task: any, idx: number) => (
        <TaskItem
          key={idx}
          activityType={task.content_type}
          openTaskPlayer={task.taskPlayer}
        />
      ))}
    </ul>
  );
};

export const TaskList = styled(_TaskList)`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid ${colors.cGray400};
`;

const ModalContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 ${dimensions.spaceM};
`;

const Row = styled.div`
  display: flex;
`;

const StartButton = styled(Knob)`
  border: 1px solid ${colors.cBlue700};
  border-radius: 4px;
  padding: 10px 16px;
`;

const _Modal: StyledFC<ModalProps> = ({
  className,
  position,
  color,
  modalTitle,
  title,
  caption,
  image,
  examples,
  objectives,
  preknowledge,
  meta,
  contentTasks,
  modalOpen,
  closeModal,
  footer,
  onStart
}) => {
  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          initial={{opacity: 0, x: 480}}
          animate={{opacity: 1, x: 0}}
          transition={{ease: 'easeOut', duration: 0.1}}
          className={className}
        >
          <DetailHeader
            contentType
            modalTitle={modalTitle}
            onClose={closeModal}
            closeFixed={true}
            rightPosition={position ? position + 16 : undefined}
          />

          <ModalContent>
            <ModuleHeader
              image={image}
              color={color}
              title={(caption ? caption + ' ' : '') + title}
              context='modal'
            />
            {objectives != null ||
              (preknowledge != null && (
                <ModuleKnowledgeIntro
                  objectives={objectives}
                  preknowledge={preknowledge}
                  context='modal'
                />
              ))}

            {contentTasks?.length > 1 && (
              <Tasks>
                <Heading>Vorbereitende Inhlate</Heading>
                <TaskList contentTasks={contentTasks} />
              </Tasks>
            )}
            <Row>
              <StartButton
                label='Starten'
                onClick={onStart}
                type={KnobType.link}
                size={KnobSize.large}
                iconPrepended={<FontAwesomeIcon icon={solid('play')} />}
              />
            </Row>
            <Row>{meta}</Row>
            {examples && (
              <SubContent>
                <DefinitionList>
                  {examples.map((example: any, i: number) => (
                    <span key={i}>
                      <Term>Aufgabe {i + 1}</Term>
                      <Desc>
                        <img
                          draggable='false'
                          src={example.image}
                          alt={`Aufgabe-${i + 1}`}
                        />
                      </Desc>
                    </span>
                  ))}
                </DefinitionList>
              </SubContent>
            )}
          </ModalContent>
          {footer && <Footer buttonLayout={ButtonLayout.row}>{footer}</Footer>}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const Modal = styled(_Modal)<Pick<ModalProps, 'asDetailTooltip'>>`
  display: flex;
  flex-direction: column;
  border-radius: ${p => (p.asDetailTooltip ? 0 : '6px')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: ${colors.cWhite};
  transition: all 0.5s ease-in-out;
  right: 0;
  ${Tasks} + ${Row}, ${Row} + ${Row} {
    margin-top: 24px;
  }
  ${SubContent} {
    margin-top: 15px;
  }

  ${Footer} {
    padding: ${dimensions.spaceS};
    border-top: 1px solid ${colors.cGray300};
  }
  @media (max-height: 600px) {
    max-height: 90vh;
  }
  ${p =>
    !p.asDetailTooltip &&
    css`
      @media (min-height: 601px) {
        max-height: 560px;
      }
    `}

  @media (min-width: 768px) {
    width: 480px;
    top: 0;
    bottom: 0;
    position: absolute;
  }
  @media (max-width: 767px) {
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${p =>
    p.modalOpen
      ? css`
          opacity: 1;
          visibility: visible;
          z-index: 10;
        `
      : css`
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
        `};
`;
