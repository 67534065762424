import styled from 'styled-components/macro';
import {Link} from 'react-router-dom';
import {colors, dimensions} from '@bettermarks/bm-ui-components';
import {LibraryGroupItem} from '../../types';
import {handleAlertMessage} from '../../helpers/Alert';

import {LibraryTile} from '../../components/LibraryTile';

export const Preamble = styled.div<{column?: boolean}>`
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  width: 100%;
  padding: ${dimensions.spaceXl} ${dimensions.spaceM} ${dimensions.spaceXs};
  p {
    margin: 0;
  }
`;

export const Group = styled.section<{disablePadding?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${p => (!p.disablePadding ? `${dimensions.spaceM}` : 0)};
  p {
    margin: 0;
  }
`;

export const Headline = styled.h1`
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 ${dimensions.spaceM};
`;

export const SubHeadline = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.cGray700};
  margin: ${dimensions.spaceXl} 0 ${dimensions.spaceM};
`;

export const TileLink = styled(Link)`
  display: inherit;
  text-decoration: none;
`;

export const AlertLink = styled.a`
  display: inherit;
  text-decoration: none;
`;

export const LibraryLinkedTile: (
  content: LibraryGroupItem,
  detailLink: string
) => JSX.Element = (content, detailLink) =>
  content.enabled ? (
    <TileLink title='Öffnen' to={detailLink} key={content.id}>
      <LibraryTile
        key={content.id}
        title={content.title}
        isActive={content.isActive}
        themeCount={content.themeCount}
        classRange={content.classRange}
        beta={content.beta}
        icon={
          content.icon && (
            <img
              alt={content.title}
              width='48'
              height='48'
              src={'/library-icons/' + content.icon}
            />
          )
        }
      />
    </TileLink>
  ) : (
    <AlertLink onClick={handleAlertMessage} key={content.id}>
      <LibraryTile
        key={content.id}
        title={content.title}
        isActive={content.isActive}
        themeCount={content.themeCount}
        classRange={content.classRange}
        beta={content.beta}
        icon={
          content.icon && (
            <img
              alt={content.title}
              width='48'
              height='48'
              src={'/library-icons/' + content.icon}
            />
          )
        }
      />
    </AlertLink>
  );
