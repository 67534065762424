import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import {
  StyledFC,
  IconBtnStyle,
  colors,
  dimensions
} from '@bettermarks/bm-ui-components';
import {AddIcon, ListIcon} from '../Icons';

import {ModuleDetailProps, ModuleProps, TileProps} from './types';

import {DetailTile} from '../DetailTile';
import {ActionTip, ActionTipProps} from '../ActionTip';
import {Knob, KnobType} from '../Knob';

const ModuleTileBase = styled.a<Pick<TileProps, 'maxTileWidth'>>`
  display: flex;
  max-width: ${p => p.maxTileWidth || 'unset'};
  position: relative;
`;

const ModuleImage = styled.span`
  img {
    max-width: 100%;
    cursor: pointer;
  }
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.color && hexToRgba(p.color, 0.2)};
`;

const ActionButton = styled(Knob)`
  display: flex;
  padding: ${dimensions.spaceXs};
  border-radius: ${dimensions.borderRadiusM};
  &:hover {
    background-color: ${colors.cWhite};
  }
`;

const ActionButtons = styled.div`
  display: flex;
`;

const ModuleDetails = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: ${dimensions.spaceXs};
`;

export const ModuleTitle = styled.h3`
  font-size: ${dimensions.fontSizeM};
  font-weight: normal;
  line-height: ${dimensions.lineHeightM2};
  color: ${colors.cGray800};
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

export const ModuleTile: StyledFC<ModuleProps & ActionTipProps> = ({
  id,
  modalTitle,
  title,
  image,
  color,
  onOpenModuleDetails,
  onOpenModule,
  dialogue,
  closeLabel,
  actionButton,
  onToggleActionTip,
  isPlanned,
  customPreviewIcon,
  customAddToPlaningIcon,
  url,
  onOpenPlanning,
  onAddToPlanning,
  buttonLayout,
  tipPosition
}) => {
  const [isActive, setActive] = React.useState(false);
  const [modalDetailProps, setModuleDetailProps] =
    React.useState<ModuleDetailProps>({
      objectives: [],
      preknowledge: [],
      examples: []
    });

  const setModalDetailsAndOpen = (modalDetails: ModuleDetailProps) => {
    setModuleDetailProps(modalDetails);
    setActive(true);
    return () => setActive(false);
  };

  const onModalToggled = (id: string) =>
    onOpenModuleDetails(id, setModalDetailsAndOpen);

  const stopProp = useCallback((e: Event | React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const [tipVisibility, setTipVisibilityTo] = useState(false);

  const {objectives, preknowledge, examples} = modalDetailProps;
  return (
    <_ModuleTile
      color={color}
      {...((url && {href: url}) || {})}
      onClick={e => {
        onOpenModule(id);
        return stopProp(e);
      }}
      data-tooltip='Anzeigen'
    >
      <ModuleImage color={color}>
        {image && <img src={image} alt={title} />}
      </ModuleImage>
      <ModuleDetails>
        <ModuleTitle>{title}</ModuleTitle>
        <ActionButtons>
          <ActionButton
            type={customPreviewIcon ? KnobType.ghost : KnobType.iconBtn}
            iconBtnStyle={IconBtnStyle.border}
            iconAppended={customPreviewIcon || <ListIcon />}
            colorizeIcon
            onClick={(e: Event | React.MouseEvent<any, MouseEvent>) => {
              onModalToggled(id);
              return stopProp(e);
            }}
          />

          <ActionButton
            type={customAddToPlaningIcon ? KnobType.ghost : KnobType.iconBtn}
            iconBtnStyle={IconBtnStyle.border}
            iconAppended={customAddToPlaningIcon || <AddIcon />}
            colorizeIcon
            onClick={(e: Event) => {
              onToggleActionTip(id, !tipVisibility, setTipVisibilityTo);
              return stopProp(e);
            }}
          />
        </ActionButtons>
      </ModuleDetails>
      <ActionTip
        dialogue={dialogue}
        activeTip={tipVisibility}
        closeLabel={closeLabel}
        onCloseTip={(e: Event) => {
          onToggleActionTip(id, false, setTipVisibilityTo);
          return stopProp(e);
        }}
        actionButton={actionButton}
        buttonLayout={buttonLayout}
        tipPosition={tipPosition}
      />
      <DetailTile
        id={id}
        color={color}
        modalTitle={modalTitle}
        title={title}
        image={image}
        objectives={objectives}
        preknowledge={preknowledge}
        examples={examples}
        isActive={isActive}
        isPlanned={isPlanned}
        onClose={(e: Event) => {
          onModalToggled(id);
          return stopProp(e);
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => stopProp(e)}
        onAddToPlanning={onAddToPlanning}
        onOpenPlanning={onOpenPlanning}
        onOpenModule={() => onOpenModule(id)}
        context='modal'
      />
    </_ModuleTile>
  );
};

const _ModuleTile = styled(ModuleTileBase)`
  cursor: pointer;
  position: relative;
  text-decoration: none;
  box-shadow: inset 0 1px 0 ${colors.cGray400};
  padding: ${dimensions.spaceS} ${dimensions.spaceXs};
  gap: ${dimensions.spaceM};
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: ${colors.cGray100};
  }
  ${ActionTip} {
    top: calc(100% - 20px);
  }
`;
